import {
  AppBar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  TextField,
  Toolbar,
  Typography,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { Autocomplete, Skeleton, Alert } from '@material-ui/lab';
import classNames from 'classnames';
import { DatePicker, DelayedLinearProgress, PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import {
  fetchJobs,
  fetchVacancyInvoices,
  fetchForwardApplicationInvoices,
  fetchVacancyPublishingOptions,
  fetchJobDetails,
  fetchJobCounts,
  exportCompanyJobs,
} from 'lib/Api/Jobs';
import {
  Job,
  JobCounts,
  JobInvoice,
  PagedJob,
  PublishingOptions,
} from 'lib/Model/Job';
import * as React from 'react';
import InvoicesDialog from './InvoicesDialog';
import PublishedOptionsDialog from './PublishedDialog';
import JobDetailsDialog from './JobDetailsDialog';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import { useLocation } from 'react-router-dom';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { endOfYear, startOfDay, startOfYear } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 100,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },
  jobColumn: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  leaderColumn: {
    width: 100,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  statusColumn: {
    width: 100,
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },
  revenueColumn: {
    width: 100,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 80,
    },
  },
  successColumn: {
    width: 150,
    textAlign: 'center',
  },
  amountColumn: {
    width: 150,
    textAlign: 'center',
  },
  centerText: {
    textAlign: 'center',
  },
  boldTitle: {
    fontWeight: 500,
    padding: 0,
    clear: 'both',
    display: 'block',
    cursor: 'default',
    textAlign: 'left',
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
  companyChip: {
    marginTop: theme.spacing(1),
  },
  smallAvatar: {
    fontSize: 10,
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  datePickerFormControl: {
    marginBottom: theme.spacing(0.5),
  },
  toolbarStats: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& .MuiGrid-item': {
        width: '100%',
        textAlign: 'center',
      },
    },
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const resourceTypeOptions = [
  {
    label: 'Forward Application',
    resource_type: 'forward_application',
  },
  {
    label: 'jobsinmalta Listing',
    resource_type: 'job_listing',
  },
  {
    label: 'jobsinmalta Listing (RSS)',
    resource_type: 'job_listing_rss',
  },
  {
    label: 'Konnekt Vacancy',
    resource_type: 'vacancy',
  },
];

export const Vacancies: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const today = React.useMemo(() => startOfDay(new Date()), []);
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [jobCounts, setJobCounts] = React.useState<JobCounts | undefined>();
  const [data, setData] = React.useState<PagedJob | undefined>();
  const [hideClosedRoles, setHideClosedRoles] = React.useState(false);
  const [currentJobTitle, setCurrentJobTitle] = React.useState('');
  const [invoices, setInvoices] = React.useState<JobInvoice[]>([]);
  const [publishOptions, setPublishOptions] = React.useState<
    PublishingOptions[]
  >([]);
  const [dialogDataLoading, setDialogDataLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [publishOptionDialogOpen, setPublishOptionDialogOpen] =
    React.useState(false);
  const [viewMoreDialogOpen, setViewMoreDialogOpen] = React.useState(false);
  const [textSearch, setTextSearch] = React.useState('');
  const [selectedJob, setSelectedJob] = React.useState<Job[]>();
  const resourceTitle = useDebounce(textSearch, 400);
  const [resourceType, setResourceType] = React.useState<string[]>([]);
  const [dateFrom, setDateFrom] = React.useState<string | undefined>(
    startOfYear(today).toISOString(),
  );
  const [dateTo, setDateTo] = React.useState<string | undefined>(
    endOfYear(today).toISOString(),
  );
  const [isExporting, setIsExporting] = React.useState(false);
  const [exportFeedback, setExportFeedback] = React.useState<{
    open: boolean;
    message: string;
    severity: 'success' | 'error';
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  React.useEffect(() => {
    setSelectedJob(undefined);
  }, [setSelectedJob]);

  const handleOpenInvoicesDialog = async (
    title: string,
    resource_id: string,
    resource_type: string,
    candidate_id?: string,
    client_id?: string,
  ) => {
    if (!candidate_id && !client_id && !resource_id) {
      return;
    }

    setCurrentJobTitle(title);
    setDialogOpen(true);
    setDialogDataLoading(true);

    if (resource_type === 'forward_app' && candidate_id && client_id) {
      const fetchedForwardApplicationInvoices =
        await fetchForwardApplicationInvoices(candidate_id, client_id);
      setInvoices(fetchedForwardApplicationInvoices);
    } else if (resource_type === 'vacancy') {
      const fetchedVacancyInvoices = await fetchVacancyInvoices(resource_id);
      setInvoices(fetchedVacancyInvoices);
    }

    setDialogDataLoading(false);
  };

  const handleOpenPublishingOptionsDialog = async (
    resource_id: string,
    title: string,
  ) => {
    setCurrentJobTitle(title);
    setPublishOptionDialogOpen(true);
    setDialogDataLoading(true);
    const publishedOptions = await fetchVacancyPublishingOptions(resource_id);
    setPublishOptions(publishedOptions);
    setDialogDataLoading(false);
  };

  const handleOpenViewMoreDialog = async (jobId: string) => {
    setViewMoreDialogOpen(true);
    setDialogDataLoading(true);
    const jobDetails = await fetchJobDetails(jobId);
    setSelectedJob(jobDetails);
    setDialogDataLoading(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setPublishOptionDialogOpen(false);
    setViewMoreDialogOpen(false);
  };

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchJobs(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      hideClosedRoles,
      withRelated,
      resourceTitle,
      resourceType,
      dateFrom,
      dateTo,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
    fetchJobCounts(companyId, withRelated).then((d) => {
      setLoading(false);
      setJobCounts(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    hideClosedRoles,
    withRelated,
    resourceTitle,
    resourceType,
    dateFrom,
    dateTo,
  ]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleExport = async () => {
    setIsExporting(true);
    try {
      await exportCompanyJobs(
        companyId,
        hideClosedRoles,
        withRelated,
        resourceTitle,
        resourceType,
        dateFrom,
        dateTo,
      );
      setExportFeedback({
        open: true,
        message: 'Jobs exported successfully',
        severity: 'success',
      });
    } catch (error) {
      setExportFeedback({
        open: true,
        message: 'Failed to export jobs',
        severity: 'error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  const columns: PageableTableHeader[] = [
    {
      key: 'dateCreated',
      label: 'Date created',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'title',
      label: 'Vacancy',
      sortable: false,
      props: {
        className: classes.jobColumn,
      },
    },
    {
      key: 'status',
      label: 'Status',
      sortable: false,
      props: {
        className: classes.statusColumn,
      },
    },
    {
      key: 'leader',
      label: 'Leader',
      sortable: false,
      props: {
        className: classes.leaderColumn,
      },
    },
    {
      key: 'revenue',
      label: '',
      sortable: false,
      props: {
        className: classes.revenueColumn,
      },
    },
    {
      key: 'closingDate',
      label: '',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'viewMore',
      label: '',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Vacancies"
        subheader="Konnekt and jobsinmalta vacancies for this company."
        style={{ paddingBottom: 0, marginBottom: 10 }}
        action={
          <React.Fragment>
            <Button
              color="secondary"
              style={{ marginRight: 8 }}
              onClick={handleExport}
              disabled={isExporting}
              startIcon={
                isExporting ? (
                  <CircularProgress size={20} color="inherit" />
                ) : undefined
              }
            >
              {isExporting ? 'Exporting...' : 'Export Jobs'}
            </Button>
            <Button color="primary" href={`/clients/${companyId}#wallet`}>
              View Wallet
            </Button>
          </React.Fragment>
        }
      />
      <CardContent>
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Type here to search for roles or applications"
                value={textSearch}
                className={classes.formControl}
                onChange={(e) => setTextSearch(e.target.value ?? '')}
                autoFocus
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                fullWidth
              >
                <Autocomplete
                  multiple
                  options={resourceTypeOptions}
                  getOptionLabel={(o) => o.label}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      label="Job Type"
                      {...params}
                      placeholder="All job types"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disableClearable={true}
                  value={resourceTypeOptions.filter((b) =>
                    resourceType.includes(b.resource_type),
                  )}
                  onChange={(e, newValues) => {
                    setResourceType(newValues.map((v) => v.resource_type));
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl margin="none" style={{ marginTop: 8 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hideClosedRoles}
                      onChange={(e, checked) => {
                        setHideClosedRoles(checked);
                      }}
                    />
                  }
                  label={'Hide closed roles'}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.datePickerFormControl} fullWidth>
                <DatePicker
                  label={'Date from'}
                  margin="none"
                  fullWidth
                  value={dateFrom}
                  inputVariant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(date: MaterialUiPickersDate) => {
                    setDateFrom(date ? date.toISOString() : undefined);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.datePickerFormControl} fullWidth>
                <DatePicker
                  label={'Date to'}
                  margin="none"
                  fullWidth
                  value={dateTo}
                  inputVariant="outlined"
                  InputLabelProps={{ shrink: true }}
                  onChange={(date: MaterialUiPickersDate) => {
                    setDateTo(date ? date.toISOString() : undefined);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      </CardContent>
      <AppBar color="primary" position="static" style={{ textAlign: 'center' }}>
        <DelayedLinearProgress loading={loading} />
        <Toolbar>
          {loading ? (
            <Skeleton variant="rect" width="100%" height={50} />
          ) : (
            <Grid container spacing={2} className={classes.toolbarStats}>
              <Grid item xs={12} md={2}>
                <Typography variant="h6">
                  {jobCounts?.total_job_listing_published ?? 0}
                </Typography>
                <Typography variant="caption">Active JIM Listings</Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography variant="h6">
                  {jobCounts?.total_job_listing_expired ?? 0}
                </Typography>
                <Typography variant="caption">Expired JIM Listings</Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography variant="h6">
                  {jobCounts?.total_job_listing_unpublished ?? 0}
                </Typography>
                <Typography variant="caption">
                  Unpublished JIM Listings
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography variant="h6">
                  {jobCounts?.total_vacancy_open ?? 0}
                </Typography>
                <Typography variant="caption">
                  Open Konnekt Vacancies
                </Typography>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography variant="h6">
                  {jobCounts?.total_vacancy_closed ?? 0}
                </Typography>
                <Typography variant="caption">
                  Closed Konnekt Vacancies
                </Typography>
              </Grid>
            </Grid>
          )}
        </Toolbar>
      </AppBar>
      <Divider />
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading={loading}
        rows={
          data?.data?.map((d, index) => ({
            key: `job_${index}`,
            cells: [
              {
                key: 'dateCreated',
                display: <DateDisplay date={d.created_on} />,
              },
              {
                key: 'title',
                display: (
                  <React.Fragment>
                    <div>
                      <Button
                        className={classNames(
                          d.resource_url
                            ? [classes.boldTitle, classes.clickable]
                            : [classes.boldTitle],
                        )}
                        variant="text"
                        onClick={
                          d.resource_url
                            ? () => {
                                window.open(d.resource_url);
                              }
                            : undefined
                        }
                      >
                        {d.title}
                      </Button>
                      {d.resource_type && (
                        <Typography
                          variant="caption"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {(() => {
                            switch (d.resource_type) {
                              case 'job_listing':
                                return 'jobsinmalta listing';
                              case 'vacancy':
                                return 'konnekt vacancy';
                              case 'job_listing_rss':
                                return 'jobsinmalta listing (RSS)';
                              default:
                                return d.resource_type.replaceAll('_', ' ');
                            }
                          })()}
                        </Typography>
                      )}
                    </div>
                    {withRelated && (
                      <RelatedCompanyChip
                        companyId={d.company_id}
                        companyName={d.company_title}
                      />
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'status',
                display: (
                  <span
                    className={classes.boldTitle}
                    style={{
                      color: ['Successful', 'Appointed', 'Published'].includes(
                        d.status,
                      )
                        ? 'green'
                        : d.is_closed
                        ? 'red'
                        : 'teal',
                      textTransform: 'uppercase',
                    }}
                  >
                    {d.status}
                  </span>
                ),
              },

              {
                key: 'leader',
                display: d.leader,
              },
              {
                key: 'value',
                display: Boolean(d.number_of_invoices) &&
                  d.number_of_invoices !== undefined && (
                    <Button
                      className={classes.clickable}
                      onClick={() => {
                        handleOpenInvoicesDialog(
                          d.title,
                          d.resource_id,
                          d.resource_type,
                          d.candidate_id ?? undefined,
                          d.company_id ?? undefined,
                        );
                      }}
                    >
                      {d.number_of_invoices} Invoice
                      {d.number_of_invoices > 1 ? 's' : ''}
                    </Button>
                  ),
              },
              {
                key: 'closingDate',
                display: Boolean(d.times_published) &&
                  d.times_published !== undefined && (
                    <Button
                      className={classes.clickable}
                      onClick={() =>
                        handleOpenPublishingOptionsDialog(
                          d.resource_id,
                          d.title,
                        )
                      }
                    >
                      {d.times_published
                        ? `Published ${d.times_published} time${
                            d.times_published > 1 ? 's' : ''
                          }`
                        : ''}
                    </Button>
                  ),
              },
              {
                key: 'viewMore',
                display: d.resource_type !== 'forward_application' && (
                  <IconButton
                    onClick={() => handleOpenViewMoreDialog(d.resource_id)}
                    style={{
                      cursor: 'pointer',
                    }}
                    color="primary"
                  >
                    <RemoveRedEye />
                  </IconButton>
                ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
      <InvoicesDialog
        open={dialogOpen}
        vacancyTitle={currentJobTitle}
        invoices={invoices}
        loading={dialogDataLoading}
        onClose={handleCloseDialog}
      />
      <PublishedOptionsDialog
        open={publishOptionDialogOpen}
        title={currentJobTitle}
        publishOptions={publishOptions}
        loading={dialogDataLoading}
        onClose={handleCloseDialog}
      />
      <JobDetailsDialog
        open={viewMoreDialogOpen}
        job={selectedJob!}
        loading={dialogDataLoading}
        onClose={handleCloseDialog}
      />
      <Snackbar
        open={exportFeedback.open}
        autoHideDuration={6000}
        onClose={() => setExportFeedback((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() =>
            setExportFeedback((prev) => ({ ...prev, open: false }))
          }
          severity={exportFeedback.severity}
        >
          {exportFeedback.message}
        </Alert>
      </Snackbar>
    </Card>
  );
};
