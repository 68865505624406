import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  QuoteResponse,
  ModuleDiscount,
  QuoteResponseLineItem,
} from 'lib/Model/QuoteGenerator';
import { generateQuote } from 'lib/Api/QuoteGenerator';
import { Alert } from '@material-ui/lab';

// better just in case, to be a bit flexible with pricing.json
const CORE_MODULES = [
  { key: 'hr_essentials_leave', label: 'HR Essentials & Leave' },
  { key: 'payroll_leave_hr_essentials', label: 'Payroll, Leave & HR' },
  { key: 'performance', label: 'Performance' },
  { key: 'time_and_attendance', label: 'Time & Attendance' },
  { key: 'training_management', label: 'Training Management' },
  { key: 'csp_per_payslip', label: 'CSP Payslips' },
];

const CORE_HARDWARE = [
  { key: 'uA760', label: 'UA760' },
  { key: 'silkBio', label: 'SilkBio 101tc' },
  {
    key: 'shared_team_virtual_punchclock',
    label: 'Shared Team Virtual Punchclock',
  },
  { key: 'rfid_em_cards', label: 'RFID EM Cards' },
];

const CORE_EXTRAS = [
  { key: 'data_migration', label: 'Data Migration' },
  { key: 'parallel_payroll_runs', label: 'Parallel Payroll Runs' },
  { key: 'signrequest', label: 'SignRequest' },
  { key: 'reporting_database', label: 'Reporting Database' },
  { key: 'onboarding_session', label: 'Onboarding Session' },
  { key: 'visitor_management', label: 'Visitor Management System' },
];

const CORE_ATS = [
  { key: 'admin_licence', label: 'HR Manager License' },
  { key: 'line_manager_licence', label: 'Hiring Manager License' },
];

const useStyles = makeStyles((theme) => ({
  stickyQuote: {
    position: 'sticky',
    top: theme.spacing(2),
    maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    overflowY: 'auto',
  },
  table: {
    minWidth: 650,
  },
  discountText: {
    color: theme.palette.error.main,
  },
}));

// Add this utility function before the component
const formatNumber = (num: number): string => {
  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const QuoteGeneratorView: React.FC = () => {
  const classes = useStyles();
  const [companySize, setCompanySize] = React.useState<number>(0);
  const [companyName, setCompanyName] = React.useState<string>('');
  const [selectedModules, setSelectedModules] = React.useState<string[]>([]);
  const [selectedHardware, setSelectedHardware] = React.useState<string[]>([]);
  const [selectedExtras, setSelectedExtras] = React.useState<string[]>([]);
  const [selectedATS, setSelectedATS] = React.useState<string[]>([]);
  const [quoteResult, setQuoteResult] = React.useState<QuoteResponse | null>(
    null,
  );
  const [quoteTimestamp, setQuoteTimestamp] = React.useState<Date | null>(null);
  const [moduleUserQty, setModuleUserQty] = React.useState<
    Record<string, number>
  >({});
  const [selectedHardwareQty, setSelectedHardwareQty] = React.useState<
    Record<string, number>
  >({});
  const [moduleDiscounts, setModuleDiscounts] = React.useState<
    Record<string, ModuleDiscount>
  >({});
  const [selectedATSQty, setSelectedATSQty] = React.useState<
    Record<string, number>
  >({});
  const [extrasQty, setExtrasQty] = React.useState<Record<string, number>>({});
  const [extrasDiscounts, setExtrasDiscounts] = React.useState<
    Record<string, { discountSetupFee: number; discountUnitPrice?: number }>
  >({});
  const [isQuoteStale, setIsQuoteStale] = React.useState<boolean>(false);
  const hasGeneratedQuote = React.useRef(false);

  React.useEffect(() => {
    setModuleUserQty((prev) => {
      const updated = { ...prev };
      selectedModules.forEach((moduleKey) => {
        if (
          moduleKey !== 'time_and_attendance' &&
          moduleKey !== 'csp_per_payslip'
        ) {
          updated[moduleKey] = companySize;
        }
      });
      return updated;
    });
  }, [companySize, selectedModules, selectedATS]);

  React.useEffect(() => {
    if (hasGeneratedQuote.current) {
      setIsQuoteStale(true);
    }
  }, [
    companySize,
    companyName,
    selectedModules,
    selectedHardware,
    selectedExtras,
    selectedATS,
    moduleUserQty,
    selectedHardwareQty,
    moduleDiscounts,
    selectedATSQty,
    extrasQty,
    extrasDiscounts,
  ]);

  const handleModuleChange = (moduleKey: string) => {
    setSelectedModules((prev) => {
      const newModules = prev.includes(moduleKey)
        ? prev.filter((key) => key !== moduleKey)
        : [...prev, moduleKey];

      if (!newModules.includes(moduleKey)) {
        // we will remove module quantity and discount when unchecked
        setModuleUserQty((prev) => {
          const updated = { ...prev };
          delete updated[moduleKey];
          return updated;
        });
        setModuleDiscounts((prev) => {
          const updated = { ...prev };
          delete updated[moduleKey];
          return updated;
        });
      } else {
        // here we set default discount when checked
        setModuleDiscounts((prev) => ({
          ...prev,
          [moduleKey]: { discountUnitPrice: 0, discountSetupFee: 0 },
        }));
        if (
          moduleKey !== 'time_and_attendance' &&
          moduleKey !== 'csp_per_payslip'
        ) {
          setModuleUserQty((prev) => ({
            ...prev,
            [moduleKey]: companySize,
          }));
        }
      }

      return newModules;
    });
  };

  const handleHardwareChange = (hardwareKey: string) => {
    setSelectedHardware((prev) => {
      const isSelected = prev.includes(hardwareKey);
      const updated = isSelected
        ? prev.filter((key) => key !== hardwareKey)
        : [...prev, hardwareKey];
      if (isSelected) {
        setModuleDiscounts((prev) => {
          const updatedDiscounts = { ...prev };
          delete updatedDiscounts[hardwareKey];
          return updatedDiscounts;
        });
      } else {
        // set default discount when hardware is checked
        setModuleDiscounts((prev) => ({
          ...prev,
          [hardwareKey]: { discountUnitPrice: 0, discountSetupFee: 0 },
        }));
      }
      return updated;
    });
    setSelectedHardwareQty((prev) => {
      const updated = { ...prev };
      if (updated[hardwareKey] !== undefined) {
        delete updated[hardwareKey];
      } else {
        updated[hardwareKey] = 1;
      }
      return updated;
    });
  };

  const handleExtrasChange = (extrasKey: string) => {
    setSelectedExtras((prev) => {
      const newExtras = prev.includes(extrasKey)
        ? prev.filter((key) => key !== extrasKey)
        : [...prev, extrasKey];

      if (!newExtras.includes(extrasKey)) {
        setExtrasQty((prev) => {
          const updated = { ...prev };
          delete updated[extrasKey];
          return updated;
        });
        setExtrasDiscounts((prev) => {
          const updated = { ...prev };
          delete updated[extrasKey];
          return updated;
        });
      } else {
        setExtrasDiscounts((prev) => ({
          ...prev,
          [extrasKey]: {
            discountSetupFee: 0,
            discountUnitPrice: 0,
          },
        }));
      }

      return newExtras;
    });
  };

  const handleATSChange = (atsKey: string) => {
    setSelectedATS((prev) => {
      const isSelected = prev.includes(atsKey);
      const updated = isSelected
        ? prev.filter((key) => key !== atsKey)
        : [...prev, atsKey];
      if (isSelected) {
        setSelectedATSQty((prevQty) => {
          const updatedQty = { ...prevQty };
          delete updatedQty[atsKey];
          return updatedQty;
        });
      }
      return updated;
    });
  };

  const handleGenerateQuote = () => {
    const moduleSelections = selectedModules.map((key) => ({
      moduleKey: key,
      userQty: moduleUserQty[key] || companySize,
      ...(moduleDiscounts[key] ? moduleDiscounts[key] : {}),
    }));
    const hardwareSelections = selectedHardware.map((key) => ({
      moduleKey: key,
      userQty: selectedHardwareQty[key] || 1,
      discountUnitPrice: moduleDiscounts[key]?.discountUnitPrice || 0,
    }));
    const extraSelections = selectedExtras.map((key) => ({
      moduleKey: key,
      userQty:
        key === 'signrequest' ||
        key === 'reporting_database' ||
        key === 'parallel_payroll_runs' ||
        key === 'onboarding_session' ||
        key === 'data_migration' ||
        key === 'visitor_management'
          ? extrasQty[key] || 0
          : undefined,
      discountSetupFee: extrasDiscounts[key]?.discountSetupFee || 0,
      discountUnitPrice: extrasDiscounts[key]?.discountUnitPrice || 0,
    }));
    const atsSelections = selectedATS.map((atsKey) => ({
      moduleKey: atsKey,
      userQty: selectedATSQty[atsKey] || companySize,
      discountUnitPrice: moduleDiscounts[atsKey]?.discountUnitPrice || 0,
      discountSetupFee: moduleDiscounts[atsKey]?.discountSetupFee || 0,
    }));
    const allSelections = [
      ...moduleSelections,
      ...hardwareSelections,
      ...extraSelections,
      ...atsSelections,
    ];

    const quote = generateQuote({
      companyName,
      companySize,
      modules: allSelections,
    });
    setQuoteResult(quote);
    setQuoteTimestamp(new Date());
    setIsQuoteStale(false);
    hasGeneratedQuote.current = true;
  };

  const clearEverything = () => {
    setCompanyName('');
    setCompanySize(0);
    setSelectedModules([]);
    setSelectedHardware([]);
    setSelectedExtras([]);
    setSelectedATS([]);
    setModuleUserQty({});
    setSelectedHardwareQty({});
    setQuoteResult(null);
    setQuoteTimestamp(null);
    hasGeneratedQuote.current = false;
  };

  const formatTime = (date: Date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  // Helper function to get module name from key
  const getModuleName = (key: string): string => {
    const module = CORE_MODULES.find((m) => m.key === key);
    if (module) return module.label;
    const hardware = CORE_HARDWARE.find((h) => h.key === key);
    if (hardware) return hardware.label;
    const extra = CORE_EXTRAS.find((e) => e.key === key);
    if (extra) return extra.label;
    const ats = CORE_ATS.find((a) => a.key === key);
    if (ats) return ats.label;
    return key; // Fallback to the key if not found
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="Quote Generator"
            subheader="Generate quotes for clients."
          />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Alert severity="info">
                  This generator is currently in beta. It is recommended to
                  cross check results against this{' '}
                  <a
                    href="https://docs.google.com/spreadsheets/d/1B5DIdp9ibjsx9oLDZ1oVGCHzS-bHIQUMkDyKHnyrTKo/edit?gid=1523236729#gid=1523236729"
                    target="_blank"
                    rel="noreferrer"
                  >
                    this sheet.
                  </a>
                </Alert>
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Company Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Company Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          //
                          label="Company Size"
                          value={companySize || ''}
                          onChange={(e) =>
                            setCompanySize(parseInt(e.target.value, 10))
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Modules</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup>
                        {CORE_MODULES.map((module) => (
                          <Box key={module.key} mb={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedModules.includes(module.key)}
                                  onChange={() =>
                                    handleModuleChange(module.key)
                                  }
                                />
                              }
                              label={module.label}
                            />
                            {selectedModules.includes(module.key) && (
                              <Grid
                                container
                                spacing={2}
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                {(module.key === 'time_and_attendance' ||
                                  module.key === 'csp_per_payslip') && (
                                  <Grid item>
                                    <TextField
                                      label={
                                        module.key === 'csp_per_payslip'
                                          ? 'Number of Payslips'
                                          : 'Number of Users'
                                      }
                                      value={moduleUserQty[module.key] ?? ''}
                                      onChange={(e) =>
                                        setModuleUserQty((prev) => ({
                                          ...prev,
                                          [module.key]: parseInt(
                                            e.target.value,
                                            10,
                                          ),
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                                <Grid item>
                                  <TextField
                                    label="Discount Unit Price"
                                    type="number"
                                    value={
                                      moduleDiscounts[module.key]
                                        ?.discountUnitPrice || 0
                                    }
                                    onChange={(e) =>
                                      setModuleDiscounts((prev) => ({
                                        ...prev,
                                        [module.key]: {
                                          ...prev[module.key],
                                          discountUnitPrice:
                                            parseFloat(e.target.value) || 0,
                                          discountSetupFee:
                                            prev[module.key]
                                              ?.discountSetupFee || 0,
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="Discount Setup Fee"
                                    type="number"
                                    value={
                                      moduleDiscounts[module.key]
                                        ?.discountSetupFee || 0
                                    }
                                    onChange={(e) =>
                                      setModuleDiscounts((prev) => ({
                                        ...prev,
                                        [module.key]: {
                                          ...prev[module.key],
                                          discountSetupFee:
                                            parseFloat(e.target.value) || 0,
                                          discountUnitPrice:
                                            prev[module.key]
                                              ?.discountUnitPrice || 0,
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Box>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">ATS</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup>
                        {CORE_ATS.map((ats) => (
                          <Box key={ats.key} mb={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedATS.includes(ats.key)}
                                  onChange={() => handleATSChange(ats.key)}
                                />
                              }
                              label={ats.label}
                            />
                            {selectedATS.includes(ats.key) && (
                              <Grid
                                container
                                spacing={2}
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                <Grid item>
                                  <TextField
                                    label="Number of Users"
                                    value={selectedATSQty[ats.key] ?? ''}
                                    onChange={(e) =>
                                      setSelectedATSQty((prev) => ({
                                        ...prev,
                                        [ats.key]: parseInt(e.target.value, 10),
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="Discount Unit Price"
                                    type="number"
                                    value={
                                      moduleDiscounts[ats.key]
                                        ?.discountUnitPrice || 0
                                    }
                                    onChange={(e) =>
                                      setModuleDiscounts((prev) => ({
                                        ...prev,
                                        [ats.key]: {
                                          ...prev[ats.key],
                                          discountUnitPrice:
                                            parseFloat(e.target.value) || 0,
                                          discountSetupFee:
                                            ats.key === 'line_manager_licence'
                                              ? 0
                                              : prev[ats.key]
                                                  ?.discountSetupFee || 0,
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                                {ats.key !== 'line_manager_licence' && (
                                  <Grid item>
                                    <TextField
                                      label="Discount Setup Fee"
                                      type="number"
                                      value={
                                        moduleDiscounts[ats.key]
                                          ?.discountSetupFee || 0
                                      }
                                      onChange={(e) =>
                                        setModuleDiscounts((prev) => ({
                                          ...prev,
                                          [ats.key]: {
                                            ...prev[ats.key],
                                            discountSetupFee:
                                              parseFloat(e.target.value) || 0,
                                            discountUnitPrice:
                                              prev[ats.key]
                                                ?.discountUnitPrice || 0,
                                          },
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </Box>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Hardware</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup>
                        {CORE_HARDWARE.map((hardware) => (
                          <Box key={hardware.key} mb={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedHardware.includes(
                                    hardware.key,
                                  )}
                                  onChange={() =>
                                    handleHardwareChange(hardware.key)
                                  }
                                />
                              }
                              label={hardware.label}
                            />
                            {selectedHardware.includes(hardware.key) && (
                              <Grid
                                container
                                spacing={2}
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                <Grid item>
                                  <TextField
                                    label="Quantity"
                                    value={
                                      selectedHardwareQty[hardware.key] || 0
                                    }
                                    onChange={(e) =>
                                      setSelectedHardwareQty((prev) => ({
                                        ...prev,
                                        [hardware.key]: parseInt(
                                          e.target.value,
                                          10,
                                        ),
                                      }))
                                    }
                                  />
                                </Grid>
                                <Grid item>
                                  <TextField
                                    label="Discount Unit Price"
                                    type="number"
                                    value={
                                      moduleDiscounts[hardware.key]
                                        ?.discountUnitPrice || 0
                                    }
                                    onChange={(e) =>
                                      setModuleDiscounts((prev) => ({
                                        ...prev,
                                        [hardware.key]: {
                                          discountUnitPrice:
                                            parseFloat(e.target.value) || 0,
                                          discountSetupFee: 0, // hardware does not use a setup discount
                                        },
                                      }))
                                    }
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Box>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Extras</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControl component="fieldset" fullWidth>
                      <FormGroup>
                        {CORE_EXTRAS.map((extra) => (
                          <Box key={extra.key} mb={2}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedExtras.includes(extra.key)}
                                  onChange={() => handleExtrasChange(extra.key)}
                                />
                              }
                              label={extra.label}
                            />
                            {selectedExtras.includes(extra.key) && (
                              <Grid
                                container
                                spacing={2}
                                style={{ marginLeft: 32, marginTop: 8 }}
                              >
                                {(extra.key === 'signrequest' ||
                                  extra.key === 'reporting_database' ||
                                  extra.key === 'parallel_payroll_runs' ||
                                  extra.key === 'data_migration' ||
                                  extra.key === 'visitor_management' ||
                                  extra.key === 'onboarding_session') && (
                                  <Grid item>
                                    <TextField
                                      label={
                                        extra.key === 'reporting_database'
                                          ? 'Number of Databases'
                                          : extra.key ===
                                            'parallel_payroll_runs'
                                          ? 'Number of Runs'
                                          : extra.key === 'data_migration'
                                          ? 'Number of Migrations'
                                          : extra.key === 'visitor_management'
                                          ? 'Number of Systems'
                                          : extra.key === 'onboarding_session'
                                          ? 'Number of Sessions'
                                          : 'Number of Users'
                                      }
                                      value={extrasQty[extra.key] || ''}
                                      onChange={(e) =>
                                        setExtrasQty((prev) => ({
                                          ...prev,
                                          [extra.key]: parseInt(
                                            e.target.value,
                                            10,
                                          ),
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                                {(extra.key === 'signrequest' ||
                                  extra.key === 'reporting_database' ||
                                  extra.key === 'visitor_management') && (
                                  <Grid item>
                                    <TextField
                                      label="Discount Unit Price"
                                      type="number"
                                      value={
                                        extrasDiscounts[extra.key]
                                          ?.discountUnitPrice || 0
                                      }
                                      onChange={(e) =>
                                        setExtrasDiscounts((prev) => ({
                                          ...prev,
                                          [extra.key]: {
                                            ...prev[extra.key],
                                            discountUnitPrice:
                                              parseFloat(e.target.value) || 0,
                                            discountSetupFee:
                                              prev[extra.key]
                                                ?.discountSetupFee || 0,
                                          },
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                                {(extra.key === 'data_migration' ||
                                  extra.key === 'parallel_payroll_runs' ||
                                  extra.key === 'onboarding_session') && (
                                  <Grid item>
                                    <TextField
                                      label="Discount Setup Fee"
                                      type="number"
                                      value={
                                        extrasDiscounts[extra.key]
                                          ?.discountSetupFee || 0
                                      }
                                      onChange={(e) =>
                                        setExtrasDiscounts((prev) => ({
                                          ...prev,
                                          [extra.key]: {
                                            ...prev[extra.key],
                                            discountSetupFee:
                                              parseFloat(e.target.value) || 0,
                                          },
                                        }))
                                      }
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </Box>
                        ))}
                      </FormGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" gridGap={16}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGenerateQuote}
                    disabled={
                      !companyName ||
                      companySize <= 0 ||
                      (selectedModules.length === 0 &&
                        selectedATS.length === 0 &&
                        selectedHardware.length === 0 &&
                        selectedExtras.length === 0) ||
                      selectedATS.some(
                        (atsKey) =>
                          !selectedATSQty[atsKey] ||
                          selectedATSQty[atsKey] <= 0,
                      )
                    }
                  >
                    Generate Quote
                  </Button>
                  <Button onClick={clearEverything} variant="outlined">
                    Clear
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {quoteResult && (
        <Grid item xs={12} md={6} className={classes.stickyQuote}>
          <Card>
            <CardHeader
              title="Quote Summary"
              subheader={`Generated quote for ${quoteResult.companyName}${
                quoteTimestamp ? ` at ${formatTime(quoteTimestamp)}` : ''
              }`}
            />
            <CardContent>
              {isQuoteStale && (
                <Box mb={2}>
                  <Alert severity="warning">
                    Detected some changes, please regenerate quote.
                    <Box mt={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateQuote}
                        size="small"
                      >
                        Regenerate Quote
                      </Button>
                    </Box>
                  </Alert>
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box component="span" fontWeight="fontWeightBold">
                      One-time Fee:
                    </Box>{' '}
                    €{formatNumber(quoteResult.oneTimeFee)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box component="span" fontWeight="fontWeightBold">
                      Unit Price:
                    </Box>{' '}
                    €{formatNumber(quoteResult.totalUnitPrice)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box component="span" fontWeight="fontWeightBold">
                      Monthly Fee:
                    </Box>{' '}
                    €{formatNumber(quoteResult.monthlyFee)}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <Box component="span" fontWeight="fontWeightBold">
                      Annual Fee:
                    </Box>{' '}
                    €{formatNumber(quoteResult.annualFee)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="quote details">
                      <TableHead>
                        <TableRow>
                          <TableCell>Module Name</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">
                            Original Unit Price
                          </TableCell>
                          <TableCell align="center">
                            Discount Unit Price
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: '#f5f5f5',
                              fontWeight: 'bold',
                            }}
                          >
                            Final Unit Price
                          </TableCell>
                          <TableCell align="center">
                            Original Setup Fee
                          </TableCell>
                          <TableCell align="center">
                            Discount Setup Fee
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              backgroundColor: '#f5f5f5',
                              fontWeight: 'bold',
                            }}
                          >
                            Final Setup Fee
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[
                          ...quoteResult.modules,
                          ...quoteResult.hardware,
                          ...quoteResult.extras,
                        ].map((item: QuoteResponseLineItem) => (
                          <TableRow key={item.moduleKey}>
                            <TableCell component="th" scope="row">
                              {getModuleName(item.moduleKey)}
                            </TableCell>
                            <TableCell align="center">{item.userQty}</TableCell>
                            <TableCell align="center">
                              {item.originalPrice
                                ? formatNumber(item.originalPrice)
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.discountText}
                            >
                              {item.discountUnitPrice
                                ? `-${formatNumber(item.discountUnitPrice)}`
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: '#f5f5f5',
                                fontWeight: 'bold',
                              }}
                            >
                              {formatNumber(
                                (item.originalPrice ?? 0) -
                                  (item.discountUnitPrice ?? 0),
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {item.originalSetupFee
                                ? formatNumber(item.originalSetupFee)
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.discountText}
                            >
                              {item.discountSetupFee
                                ? `-${formatNumber(item.discountSetupFee)}`
                                : '-'}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                backgroundColor: '#f5f5f5',
                                fontWeight: 'bold',
                              }}
                            >
                              {formatNumber(
                                (item.originalSetupFee ?? 0) -
                                  (item.discountSetupFee ?? 0),
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default QuoteGeneratorView;
