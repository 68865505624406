import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import { DateTimeFormat } from 'config';
import { format, parseISO } from 'date-fns';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchActivity, fetchActivityFeedDetail } from 'lib/Api/Activity';
import { fetchNpsLeaders } from 'lib/Api/Nps';
import { getUserData } from 'lib/Helper/Auth';
import { PagedActivity, Activity as ActivityEntity } from 'lib/Model/Activity';
import { NpsLeader } from 'lib/Model/Nps';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  dateColumn: {
    width: 60,
    textAlign: 'center',
    paddingRight: 0,
  },
  typeColumn: {
    width: 120,
    textAlign: 'center',
  },
  keyHeading: {
    fontWeight: 500,
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  keyValue: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
  },
  keyRow: {
    marginBottom: theme.spacing(2),
  },
  activityRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    transition: theme.transitions.create('background-color'),
  },
  stickyCard: {
    position: 'sticky',
    top: theme.spacing(2),
  },
  activityValue: {
    '& br': {
      marginBottom: theme.spacing(1),
    },
  },
  summaryText: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
}));

export const ActivityFeed: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);

  const [resourceType, setResourceType] = React.useState<string[]>([]);
  const user = getUserData();
  const isCAM = user?.group?.includes('Business Development') || false;
  const [loadingCamsList, setLoadingCamsList] = React.useState(false);
  const [camsList, setCamsList] = React.useState<NpsLeader[]>([]);
  const [selectedActivity, setSelectedActivity] = React.useState<
    ActivityEntity | undefined
  >();
  const [selectedCams, setSelectedCams] = React.useState<NpsLeader[]>(
    isCAM && user
      ? [
          {
            reviewee: user?.name,
            team_identifier: user?.group,
            terminated: 0,
          },
        ]
      : [],
  );
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const fetchLeaders = React.useCallback(() => {
    setLoadingCamsList(true);
    // d is the data, we filter on team id and terminated  to get only active bd cams
    fetchNpsLeaders().then((d) => {
      const activeCams = d.filter(
        (leader) =>
          leader.team_identifier === 'Business Development' &&
          leader.terminated === 0,
      );
      setLoadingCamsList(false);
      setCamsList(activeCams);
    });
  }, [setLoadingCamsList, setCamsList]);

  const [data, setData] = React.useState<PagedActivity | undefined>();
  const [singleActivityData, setSingleActivityData] = React.useState<
    any | undefined
  >();
  const [loadingSingleActivity, setLoadingSingleActivity] =
    React.useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    if (selectedActivity === undefined) {
      setSingleActivityData(undefined);
      return;
    }
    setLoadingSingleActivity(true);
    fetchActivityFeedDetail(selectedActivity.id).then((d) => {
      setLoadingSingleActivity(false);
      setSingleActivityData(d);
    });
  }, [selectedActivity, setSingleActivityData]);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchActivity(
      null,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      false,
      selectedCams.map((cam) => cam.reviewee),
      resourceType,
    ).then((d) => {
      setData(d);
      setLoading(false);
    });
  }, [pageNumber, tableSettings.rowsPerPage, selectedCams, resourceType]);

  React.useEffect(() => {
    fetchData();
    fetchLeaders();
  }, [fetchData, fetchLeaders]);

  React.useEffect(() => {
    setPageNumber(0);
  }, [resourceType, selectedCams]);

  const columns: PageableTableHeader[] = [
    {
      key: 'date',
      label: '',
      sortable: false,
    },
    {
      key: 'type',
      label: 'Type',
      sortable: false,
    },
    {
      key: 'summary',
      label: '',
      sortable: false,
    },
  ];

  const resourceTypeOptions = [
    {
      label: 'Call',
      resource_type: 'call',
    },
    {
      label: 'Deal',
      resource_type: 'deal',
    },
    {
      label: 'Forward Application',
      resource_type: 'forward_application',
    },
    {
      label: 'Invoice',
      resource_type: 'invoice',
    },
    {
      label: 'Job Listing',
      resource_type: 'job_listing',
    },
    {
      label: 'Konnekt Vacancy',
      resource_type: 'vacancy',
    },
    {
      label: 'Meeting',
      resource_type: 'meeting',
    },
    {
      label: 'Note',
      resource_type: 'note',
    },
    {
      label: 'NPS Submission',
      resource_type: 'nps_submission',
    },
  ];

  return (
    <Grid container style={{ minHeight: '100vh' }}>
      <Grid
        item
        xs={12}
        md={!selectedActivity ? 12 : 6}
        hidden={Boolean(selectedActivity) && isSmallScreen}
      >
        <Card className={classes.card}>
          <CardHeader
            title="Activity Feed"
            subheader="Full activity feed of all our clients from all our platforms. You can click the log entry or visit the company's profile for more information."
            action={
              <Button
                color="secondary"
                href="https://docs.google.com/document/d/1KI-fZ_E-io0DvPgdQ3tx8LPLRgfgo5Ojj5udRQtpKVs/edit#heading=h.auau3h1nuohr"
                target="_blank"
              >
                Help
              </Button>
            }
          />
          <CardContent style={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  options={resourceTypeOptions}
                  getOptionLabel={(o) => o.label}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      label="Activity Type"
                      placeholder="Showing all activity"
                      {...params}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disableClearable={true}
                  value={resourceTypeOptions.filter((b) =>
                    resourceType.includes(b.resource_type),
                  )}
                  loadingText="Loading..."
                  onChange={(e, newValues) => {
                    setResourceType(newValues.map((v) => v.resource_type));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  loading={loadingCamsList}
                  getOptionLabel={(o) => `${o.reviewee}`}
                  getOptionSelected={(o) =>
                    selectedCams.map((c) => c.reviewee).includes(o.reviewee)
                  }
                  multiple
                  options={camsList}
                  renderInput={(params) => (
                    <TextField
                      variant="outlined"
                      label="Client Account Manager"
                      placeholder="Filter activity of clients based on their CAM"
                      {...params}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  value={selectedCams ?? null}
                  loadingText="Loading..."
                  onChange={(e, newValue) => {
                    setSelectedCams(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <PageableTable
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            showSkeletonLoading
            hideColumns={true}
            rows={
              data?.data?.map((d) => ({
                key: d.id,
                props: {
                  style: {
                    cursor: 'pointer',
                  },
                  onClick: () => {
                    setSelectedActivity(d);
                  },
                },
                cells: [
                  {
                    key: 'date',
                    display: d.created_on && (
                      <DateDisplay date={d.created_on} />
                    ),
                    props: {
                      className: classes.dateColumn,
                    },
                  },
                  {
                    key: 'type',
                    display: (
                      <React.Fragment>
                        <strong>
                          {d.resource_type.replaceAll('_', ' ').toUpperCase()}
                        </strong>
                        <br />
                      </React.Fragment>
                    ),

                    props: {
                      className: classes.typeColumn,
                    },
                  },
                  {
                    key: 'summary',
                    display: (
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              (d.value?.replace(/(?:\r\n|\r|\n)/g, '<br>') ??
                                '') +
                              (typeof d.value === 'string' &&
                              d.value.length > 50
                                ? '...'
                                : ''),
                          }}
                        />

                        <RelatedCompanyChip
                          companyId={d.company_id}
                          companyName={d.company_title}
                        />
                      </div>
                    ),
                  },
                ],
              })) ?? []
            }
            loading={loading}
            pageNumber={pageNumber}
            rowCount={data?.total_count ?? 0}
            columns={columns}
            onChangePage={setPageNumber}
          />
        </Card>
      </Grid>
      {selectedActivity && (
        <Grid item xs={12} md={6}>
          <Card className={`${classes.card} ${classes.stickyCard}`}>
            <CardHeader
              title={
                !selectedActivity.created_on
                  ? 'Activity'
                  : format(
                      parseISO(selectedActivity.created_on),
                      DateTimeFormat.LONG_FRIENDLY,
                    )
              }
              action={
                <IconButton onClick={() => setSelectedActivity(undefined)}>
                  <Close />
                </IconButton>
              }
              subheader={`${selectedActivity.platform
                .toUpperCase()
                .replace('KNT', 'ATS')} ${selectedActivity.resource_type
                .replaceAll('_', ' ')
                .toUpperCase()}`}
            />
            {loadingSingleActivity ? (
              <Skeleton style={{ height: 300 }} variant="rect" />
            ) : (
              <CardContent>
                <div>
                  {singleActivityData &&
                    Object.keys(singleActivityData).map((key) =>
                      singleActivityData[key] ? (
                        <div key={key} className={classes.keyRow}>
                          <div className={classes.keyHeading}>
                            {key.replaceAll('_', ' ')}
                          </div>
                          <div className={classes.keyValue}>
                            <Typography variant="body1">
                              {key === 'created_on' ||
                              key === 'last_stage_change_at' ? (
                                format(
                                  parseISO(singleActivityData[key]),
                                  DateTimeFormat.LONG,
                                )
                              ) : (
                                <div
                                  className={classes.activityValue}
                                  dangerouslySetInnerHTML={{
                                    __html: singleActivityData[key]
                                      .toString()
                                      .replace(/(?:\r\n|\r|\n)/g, '<br>'),
                                  }}
                                />
                              )}
                            </Typography>
                          </div>
                        </div>
                      ) : null,
                    )}
                </div>
              </CardContent>
            )}
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
