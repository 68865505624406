import {
  Avatar,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Check } from '@material-ui/icons';
import {
  HelpPopper,
  PageableTable,
  PopperDropdownMenu,
  SnackbarContext,
} from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { actionSuggestion, fetchSuggestionsForCompany } from 'lib/Api/Matches';
import { getUserData } from 'lib/Helper/Auth';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedSuggestions, Suggestion } from 'lib/Model/Suggestion';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactColumn: {},
  contactsColumn: {
    width: 135,
    textAlign: 'right',
    paddingLeft: 0,
  },
  smallColumn: {
    width: 50,
    textAlign: 'right',
    paddingLeft: 0,
  },
  avatarColumn: {
    width: 30,
    paddingRight: 0,
    verticalAlign: 'top',
    paddingTop: theme.spacing(3),
  },
  smallAvatar: {
    width: 26,
    height: 26,
    fontSize: 14,
  },
}));

interface Props {
  companyId: string;
  doCompanyRefresh(): void;
}

export const SuggestedMatches: React.FC<Props> = ({
  companyId,
  doCompanyRefresh,
}) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const [confirmMergeOpen, setConfirmMergeOpen] = React.useState(false);
  const [selectedSuggestion, setSelectedSuggestion] =
    React.useState<Suggestion | null>(null);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const snackbar = React.useContext(SnackbarContext);
  const [data, setData] = React.useState<PagedSuggestions | undefined>();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const user = getUserData();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchSuggestionsForCompany(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [setLoading, setData, pageNumber, tableSettings.rowsPerPage, companyId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'avatar',
      label: '',
      sortable: false,
    },
    {
      key: 'contact',
      label: 'Contact',
      sortable: false,
    },
    {
      key: 'contacts',
      label: '',
      sortable: false,
    },
  ];

  async function onAccept(d: Suggestion) {
    setSelectedSuggestion(d);
    setConfirmMergeOpen(true);
  }

  async function handleConfirmMerge() {
    if (!selectedSuggestion) return;

    setLoading(true);
    try {
      await actionSuggestion(
        companyId,
        selectedSuggestion.suggestion_id,
        'merge',
      );
      snackbar.success('Companies merged successfully.');
      doCompanyRefresh();
    } catch (ex) {
      snackbar.error(`Failed to merge companies: ${ex}`);
    } finally {
      setLoading(false);
      setConfirmMergeOpen(false);
      setSelectedSuggestion(null);
    }
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title={`Suggested matches`}
          subheader={
            <span>
              Suggested companies potentially identical to this one.
              {user?.permissions?.includes('merge') && (
                <span>
                  <br />
                  Click the tick button if you are sure the suggested company
                  below is <strong>IDENTICAL</strong> to this one.
                </span>
              )}
            </span>
          }
          action={
            <HelpPopper style={{ padding: 8 }}>
              <Typography variant="body2" gutterBottom>
                Every day, suggested similar profiles to this company will be
                generated.
              </Typography>
              <Typography variant="body2" gutterBottom>
                The original platform is displayed as well as what the
                suggestion is based on.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Suggestions are based on exact name matches, matching contact
                emails, matching contact email domains, or matching registration
                numbers.
              </Typography>
              <Typography variant="body2" gutterBottom>
                If you know a company that should be suggested but isn't, please
                reach out to a member from the insights team.
              </Typography>
            </HelpPopper>
          }
        />
        <Divider />
        <PageableTable
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          showSkeletonLoading
          hideColumns
          rows={
            data?.data?.map((d, index) => {
              const menuItems = [
                {
                  label: 'Accept merge',
                  onClick: () => onAccept(d),
                  icon: Check,
                  iconColor: green[500],
                },
              ];
              return {
                key: index.toString(),
                cells: [
                  {
                    key: 'avatar',
                    display: d.alias_company_name && (
                      <Avatar
                        {...stringAvatar(d.alias_company_name)}
                        variant="rounded"
                        className={classes.smallAvatar}
                      />
                    ),
                    props: {
                      className: classes.avatarColumn,
                    },
                  },
                  {
                    key: 'contact',
                    display: (
                      <React.Fragment>
                        <Button
                          variant="text"
                          color="primary"
                          style={{
                            padding: 0,
                            textAlign: 'left',
                            justifyContent: 'flex-start',
                          }}
                          onClick={() => onOpenDetail(d)}
                        >
                          {d.alias_company_name}
                        </Button>
                        <div>
                          <Typography variant="caption">
                            {d.alias_cnumber
                              ? `C-Number: ${d.alias_cnumber}, `
                              : ''}
                            {d.alias_vat_number
                              ? `VAT Number: ${d.alias_vat_number}`
                              : ''}
                          </Typography>
                        </div>
                        <div>
                          <Typography variant="caption">
                            {resolvePlatformName(d.alias_id)}: {d.match_type}{' '}
                            match
                          </Typography>
                        </div>
                      </React.Fragment>
                    ),
                  },
                  {
                    key: 'contacts',
                    display:
                      user?.permissions?.includes('merge') &&
                      (isDesktop ? (
                        <ButtonGroup disableElevation>
                          {menuItems.map((m, menuItemIndex) => (
                            <Tooltip
                              title={m.label}
                              key={`button_${index}_${menuItemIndex}`}
                            >
                              <Button
                                size="small"
                                style={{
                                  color: m.iconColor,
                                }}
                                onClick={m.onClick}
                              >
                                <m.icon />
                              </Button>
                            </Tooltip>
                          ))}
                        </ButtonGroup>
                      ) : (
                        <PopperDropdownMenu menuItems={menuItems} />
                      )),
                    props: {
                      className: isDesktop
                        ? classes.contactsColumn
                        : classes.smallColumn,
                    },
                  },
                ],
              };
            }) ?? []
          }
          loading={loading}
          pageNumber={pageNumber}
          rowCount={data?.total_count ?? 0}
          columns={columns}
          onChangePage={setPageNumber}
        />
      </Card>
      <Dialog
        open={confirmMergeOpen}
        onClose={() => setConfirmMergeOpen(false)}
      >
        <DialogTitle>Confirm Merge</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to merge{' '}
            {selectedSuggestion?.alias_company_name} into this company? This
            action can have important consequences on process such as PROACT,
            revenue calculations and more.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmMergeOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmMerge}
            color="primary"
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Confirm Merge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  function onOpenDetail(d: Suggestion) {
    window.open(`/clients/${d.alias_id}`);
  }
};

export function resolvePlatformName(alias_id: string) {
  if (!alias_id) {
    return '';
  }
  const parts = alias_id.toUpperCase().split('-');
  if (parts.length < 1) {
    return alias_id;
  }
  return resolvePlatformNameFromPrefix(parts[0]);
}

export function resolvePlatformNameFromPrefix(prefix: string) {
  switch (prefix.toUpperCase()) {
    case 'KNT':
      return 'Konnekt ATS';
    case 'TLX':
      return 'Talexio';
    case 'JIM':
      return 'jobsinmalta';
    case 'PM':
      return 'Payroll Malta';
    case 'PM-':
      return 'Payroll Malta';
    case 'KMP':
      return 'KeepMePosted';
    case 'XRO':
      return 'Xero';
    case 'XRI':
      return 'Xero ITV';
    case 'XRT':
      return 'Xero Talexio';
    case 'XRK':
      return 'Xero Konnekt';
    case 'SIM':
      return 'Salariesinmalta';
    case 'ZND':
      return 'Zendesk Sell';
    case 'ALF':
      return 'Alfred Jobs';
    case 'ZNS':
      return 'Zendesk Support';
    case 'MND':
      return 'Monday.com';
    default:
      return prefix;
  }
}

export function resolveMergedAliasesToPlatformNames(
  merged_aliases: string | null,
) {
  if (!merged_aliases) {
    return [];
  }
  const aliases = merged_aliases.toUpperCase().split(',');
  return aliases.map((alias) => resolvePlatformNameFromPrefix(alias));
}
