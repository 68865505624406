import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popper,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { DatePicker, PageableTable } from 'components';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { fetchAllMeetings, exportMeetings } from 'lib/Api/Meetings';
import { PagedMeeting, Meeting } from 'lib/Model/Meetings';
import * as React from 'react';
import { stringAvatar } from 'lib/Helper/Avatars';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useLocalStorageSettings,
} from 'hooks';
import { RelatedCompanyChip } from 'components/RelatedCompanyChip';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { endOfDay, startOfDay, startOfMonth } from 'date-fns';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  meetingDateColumn: {
    width: 60,
    textAlign: 'center',
    paddingRight: 0,
  },
  datePickerFormControl: {
    marginBottom: theme.spacing(0.5),
  },
  attendeeList: {
    maxHeight: 300,
    overflow: 'auto',
  },
  dateColumn: {
    width: 120,
  },
  smallAvatar: {
    marginRight: theme.spacing(1),
    width: 20,
    height: 20,
    fontSize: 12,
  },
  itemIcon: {
    marginRight: theme.spacing(0.5),
  },
  accountManager: {
    display: 'flex',
    alignItems: 'center',
    gap: '1px',
    border: 'none',
    marginBottom: theme.spacing(1),
    marginLeft: 0,
  },
  avatarGroupRoot: {
    position: 'relative',
    zIndex: 0,
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderColor: '#000000',
    '& .MuiAvatarGroup-avatar': {
      marginLeft: 0,
      width: 20,
      height: 20,
      fontSize: 12,
      border: '2px solid',
    },
    '&. MuiAvatar-circular': {
      marginLeft: 0,
      width: 20,
      height: 20,
      fontSize: 12,
    },
  },

  attendeesPopper: {
    width: 350,
    background: theme.palette.common.white,
  },
  formControl: {
    marginBottom: theme.spacing(0.5),
  },
  textControl: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiCardHeader-action': {
        marginRight: 0,
        marginTop: theme.spacing(2),
        alignSelf: 'stretch',
      },
    },
  },
  headerActions: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
  cardTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  cardSubheader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

export const MeetingsView: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);

  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const today = React.useMemo(() => startOfDay(new Date()), []);
  const [dateFrom, setDateFrom] = React.useState<string | undefined>(
    startOfMonth(today).toISOString(),
  );
  const [dateTo, setDateTo] = React.useState<string | undefined>(
    endOfDay(today).toISOString(),
  );
  const [data, setData] = React.useState<PagedMeeting | undefined>();
  const [textSearch, setTextSearch] = React.useState<string | undefined>();
  const debouncedTextSearch = useDebounce(textSearch, 400);
  const [selectedMeeting, setSelectedMeeting] = React.useState<
    Meeting | undefined
  >();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [unitName, setUnitName] = React.useState<string>('All');

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchAllMeetings(
      pageNumber + 1,
      tableSettings.rowsPerPage,
      dateFrom,
      dateTo,
      debouncedTextSearch,
      unitName,
    )
      .then((d) => {
        setLoading(false);
        setData(d);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [
    pageNumber,
    tableSettings.rowsPerPage,
    debouncedTextSearch,
    dateFrom,
    dateTo,
    setLoading,
    setData,
    unitName,
  ]);

  const exportData = React.useCallback(() => {
    exportMeetings(dateFrom, dateTo, debouncedTextSearch, unitName);
  }, [debouncedTextSearch, dateFrom, dateTo, unitName]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    setAnchorEl(null);
    setSelectedMeeting(undefined);
  }, [setAnchorEl, setSelectedMeeting]);

  const columns: PageableTableHeader[] = [
    {
      key: 'meeting_date',
      label: 'Meeting Date',
      sortable: false,
    },
    {
      key: 'meeting_title',
      label: 'Meeting Title',
      sortable: false,
    },
    {
      key: 'cams',
      label: 'CAM(s)',
      sortable: false,
    },
    {
      key: 'recruiters',
      label: 'Recruiter(s)',
      sortable: false,
    },
    {
      key: 'Organizer',
      label: 'Organizer',
      sortable: false,
    },
    {
      key: 'individuals',
      label: 'Attendees',
      sortable: false,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          subheader: classes.cardSubheader,
        }}
        title="Expedition42 Meetings"
        subheader="Client Meetings from all Expedition42 Team Members."
        action={
          <div className={classes.headerActions}>
            <Button
              color="primary"
              href="https://calendar.google.com/"
              target="_blank"
            >
              Google Calendar
            </Button>
            <Button color="secondary" onClick={exportData}>
              Export Data
            </Button>
          </div>
        }
      />
      <CardContent style={{ paddingTop: 0, paddingBottom: 8 }}>
        <TextField
          className={classes.textControl}
          value={textSearch}
          variant="outlined"
          autoFocus
          onChange={(e) => setTextSearch(e.currentTarget.value ?? undefined)}
          fullWidth
          placeholder="Type here to search for events or attendees"
        />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <FormControl
              className={classes.formControl}
              variant="outlined"
              fullWidth
            >
              <Autocomplete
                options={[
                  'All',
                  'Business Development',
                  'General',
                  'IT & iGaming',
                  'Finance & Legal',
                  'Directors',
                ]}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    label="Department"
                    {...params}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disableClearable={true}
                value={unitName}
                onChange={(e, newValue) => {
                  setUnitName(newValue);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl className={classes.datePickerFormControl} fullWidth>
              <DatePicker
                label={'Date from'}
                margin="none"
                fullWidth
                value={dateFrom}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  setDateFrom(date ? date.toISOString() : undefined);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl className={classes.datePickerFormControl} fullWidth>
              <DatePicker
                label={'Date to'}
                margin="none"
                fullWidth
                value={dateTo}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                onChange={(date: MaterialUiPickersDate) => {
                  setDateTo(date ? date.toISOString() : undefined);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading={true}
        rows={
          data?.data?.map((d: Meeting, index: number) => {
            return {
              key: index.toString(),
              cells: [
                {
                  key: 'meeting_date',
                  props: {
                    className: classes.dateColumn,
                  },
                  display: d.meeting_date && d.meeting_date !== 'NaT' && (
                    <DateDisplay date={d.meeting_date} />
                  ),
                },
                {
                  key: 'meeting_title',
                  display: (
                    <React.Fragment>
                      <Typography>{d.meeting_title}</Typography>
                      {d.company_id && d.company_title ? (
                        <RelatedCompanyChip
                          companyId={d.company_id.split(',')[0].trim()}
                          companyName={d.company_title.split(',')[0].trim()}
                        />
                      ) : null}
                    </React.Fragment>
                  ),
                },
                {
                  key: 'cams',
                  display: d.cams && (
                    <div className={classes.itemIcon}>
                      {/* Should probably put the tooltip on the second box rather than the first */}
                      <Tooltip title={d.cams.split(', ').slice(2).join(', ')}>
                        <Box display="flex" flexDirection="column">
                          {d.cams
                            .split(', ')
                            .slice(0, 2)
                            .map((cam, index) => (
                              <Box
                                className={classes.accountManager}
                                sx={{
                                  border: '2px solid #fff',
                                  zIndex: 100 - index,
                                }}
                              >
                                {/* Ditched avatar group because we couldn't override its styling and ended up being
                                way bigger than the other avatars */}
                                <Avatar
                                  className={classes.smallAvatar}
                                  {...stringAvatar(cam)}
                                />
                                <Typography variant="body2">{cam}</Typography>
                              </Box>
                            ))}
                          {d.cams.split(', ').length > 2 && (
                            <Box
                              className={classes.accountManager}
                              sx={{ border: '2px solid #fff', zIndex: 0 }}
                            >
                              {/* Not the cleanest but we'll manually calculate the remaining and show the number ourselves */}
                              <Avatar className={classes.smallAvatar}>
                                +{d.cams.split(', ').length - 2}
                              </Avatar>
                            </Box>
                          )}
                        </Box>
                      </Tooltip>
                    </div>
                  ),
                },
                {
                  key: 'recruiters',
                  display: d.recruiters && (
                    <div className={classes.itemIcon}>
                      <Tooltip
                        title={d.recruiters.split(', ').slice(2).join(', ')}
                      >
                        <Box display="flex" flexDirection="column">
                          {d.recruiters
                            .split(', ')
                            .slice(0, 2)
                            .map((cam, index) => (
                              <Box
                                className={classes.accountManager}
                                sx={{
                                  border: '2px solid #fff',
                                  zIndex: 100 - index,
                                }}
                              >
                                <Avatar
                                  className={classes.smallAvatar}
                                  {...stringAvatar(cam)}
                                />
                                <Typography variant="body2">{cam}</Typography>
                              </Box>
                            ))}
                          {d.recruiters.split(', ').length > 2 && (
                            <Box
                              className={classes.accountManager}
                              sx={{ border: '2px solid #fff', zIndex: 0 }}
                            >
                              <Avatar className={classes.smallAvatar}>
                                +{d.recruiters.split(', ').length - 2}
                              </Avatar>
                            </Box>
                          )}
                        </Box>
                      </Tooltip>
                    </div>
                  ),
                },
                {
                  key: 'organizer',
                  display: d.organizer,
                },
                {
                  key: 'individuals',
                  display: (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        setAnchorEl(e.currentTarget);
                        setSelectedMeeting(d);
                      }}
                    >
                      {d.attendee_emails?.split(',').length || 0} Attendees
                    </Button>
                  ),
                },
              ],
            };
          }) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />

      <Popper
        open={Boolean(selectedMeeting)}
        anchorEl={anchorEl} // Used to hide menu when clicking an option
      >
        <Card className={classes.attendeesPopper}>
          <CardHeader
            title={selectedMeeting?.meeting_title ?? 'Meeting'}
            action={
              <IconButton
                onClick={() => {
                  setSelectedMeeting(undefined);
                  setAnchorEl(null);
                }}
              >
                <Close />
              </IconButton>
            }
          />
          <List dense className={classes.attendeeList}>
            {selectedMeeting?.attendee_emails.split(',').map((a, index) => {
              const attendeeData = a.trim().split(' ');
              const attendeeEmail =
                attendeeData.length > 1 ? attendeeData[0] : a;
              const attendeeSecondary =
                attendeeData.length > 1
                  ? attendeeData
                      .slice(1)
                      .join(' ')
                      .replace('(', '')
                      .replace(')', '')
                  : undefined;

              return (
                <ListItem key={index}>
                  <ListItemText
                    primary={attendeeEmail}
                    secondary={attendeeSecondary}
                  />
                </ListItem>
              );
            })}
          </List>
        </Card>
      </Popper>
    </Card>
  );
};
