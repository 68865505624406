import { Box, Chip } from '@material-ui/core';
import { format } from 'date-fns';
import React from 'react';
import { DateFormat } from 'config';

interface NpsFilterChipsProps {
  filters: {
    label: string;
    value: string;
    onDelete: () => void;
  }[];
}

export const NpsFilterChips: React.FC<NpsFilterChipsProps> = ({ filters }) => {
  if (filters.length === 0) return null;

  return (
    <Box display="flex" flexWrap="wrap" p={2}>
      {filters.map((filter, index) => (
        <Box key={`${filter.label}-${index}`} mr={1} mb={1}>
          <Chip
            label={`${filter.label}: ${filter.value}`}
            onDelete={filter.onDelete}
            color="primary"
            variant="outlined"
          />
        </Box>
      ))}
    </Box>
  );
};

export const generateFilterChips = ({
  textSearch,
  submissionStatus,
  ratingType,
  userType,
  brand,
  reviewee,
  dateFrom,
  dateTo,
  dateHandled,
  actionNeeded,
  actionBy,
  team,
  submissionType,
  tierType,
  showTtv,
  showCommentRecords,
  setters,
}: {
  textSearch?: string;
  submissionStatus?: string;
  ratingType: string[];
  userType: string[];
  brand: string[];
  reviewee: string[];
  dateFrom?: Date;
  dateTo: Date;
  dateHandled?: string;
  actionNeeded?: string;
  actionBy?: string;
  team: string[];
  submissionType?: string;
  tierType?: string;
  showTtv: boolean;
  showCommentRecords: boolean;
  setters: {
    setTextSearch: (value?: string) => void;
    setSubmissionStatus: (value?: string) => void;
    setRatingType: (value: string[]) => void;
    setUserType: (value: string[]) => void;
    setBrand: (value: string[]) => void;
    setReviewee: (value: string[]) => void;
    setDateFrom: (value?: Date) => void;
    setDateTo: (value: Date) => void;
    setdateHandled: (value?: string) => void;
    setActionNeeded: (value?: string) => void;
    setActionBy: (value?: string) => void;
    setTeam: (value: string[]) => void;
    setSubmissionType: (value?: string) => void;
    setTierType: (value?: string) => void;
    setShowTtv: (value: boolean) => void;
    setShowCommentRecords: (value: boolean) => void;
  };
}) => {
  const filters: NpsFilterChipsProps['filters'] = [];

  if (textSearch) {
    filters.push({
      label: 'Search',
      value: textSearch,
      onDelete: () => setters.setTextSearch(undefined),
    });
  }

  if (submissionStatus) {
    filters.push({
      label: 'Status',
      value: submissionStatus,
      onDelete: () => setters.setSubmissionStatus(undefined),
    });
  }

  ratingType.forEach((type) => {
    filters.push({
      label: 'Rating',
      value: type,
      onDelete: () =>
        setters.setRatingType(ratingType.filter((t) => t !== type)),
    });
  });

  userType.forEach((type) => {
    filters.push({
      label: 'User Type',
      value: type,
      onDelete: () => setters.setUserType(userType.filter((t) => t !== type)),
    });
  });

  brand.forEach((b) => {
    filters.push({
      label: 'Brand',
      value: b,
      onDelete: () => setters.setBrand(brand.filter((br) => br !== b)),
    });
  });

  reviewee.forEach((r) => {
    filters.push({
      label: 'Reviewee',
      value: r,
      onDelete: () => setters.setReviewee(reviewee.filter((rev) => rev !== r)),
    });
  });

  if (dateFrom) {
    filters.push({
      label: 'From',
      value: format(dateFrom, DateFormat.SHORT),
      onDelete: () => setters.setDateFrom(undefined),
    });
  }

  if (dateTo) {
    filters.push({
      label: 'To',
      value: format(dateTo, DateFormat.SHORT),
      onDelete: () => setters.setDateTo(dateTo),
    });
  }

  if (dateHandled) {
    filters.push({
      label: 'Handled',
      value: dateHandled,
      onDelete: () => setters.setdateHandled(undefined),
    });
  }

  if (actionNeeded) {
    filters.push({
      label: 'Action Needed',
      value: actionNeeded,
      onDelete: () => setters.setActionNeeded(undefined),
    });
  }

  if (actionBy) {
    filters.push({
      label: 'Action By',
      value: actionBy,
      onDelete: () => setters.setActionBy(undefined),
    });
  }

  team.forEach((t) => {
    filters.push({
      label: 'Team',
      value: t,
      onDelete: () => setters.setTeam(team.filter((tm) => tm !== t)),
    });
  });

  if (submissionType) {
    filters.push({
      label: 'Submission Type',
      value: submissionType,
      onDelete: () => setters.setSubmissionType(undefined),
    });
  }

  if (tierType) {
    filters.push({
      label: 'Tier',
      value: tierType,
      onDelete: () => setters.setTierType(undefined),
    });
  }

  if (showTtv) {
    filters.push({
      label: 'Team Voice Only',
      value: 'Yes',
      onDelete: () => setters.setShowTtv(false),
    });
  }

  if (showCommentRecords) {
    filters.push({
      label: 'With Comments Only',
      value: 'Yes',
      onDelete: () => setters.setShowCommentRecords(false),
    });
  }

  return filters;
};
