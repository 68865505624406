import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import { Comment, Flag, RemoveRedEye } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert, Autocomplete } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import classNames from 'classnames';
import { DatePicker, PageableTable } from 'components';
import { DateDisplay } from 'components/DateDisplay/DateDisplay';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateTimeFormat } from 'config';
import {
  endOfDay,
  format,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import {
  getTableSettingsStorageKey,
  TableSettings,
  useDebounce,
  useDocumentTitle,
  useLocalStorageSettings,
} from 'hooks';
import { fetchJobDetails } from 'lib/Api/Jobs';
import {
  exportNpsResponses,
  fetchNpsLeaders,
  fetchNpsResponses,
} from 'lib/Api/Nps';
import { getUserData } from 'lib/Helper/Auth';
import { Job } from 'lib/Model/Job';
import { NpsLeader, NpsResponse, PagedNpsResponse } from 'lib/Model/Nps';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import JobDetailsDialog from 'views/Clients/components/JobDetailsDialog';
import { resolvePlatformName } from 'views/Clients/components/SuggestedMatches';
import { brandList, NpsDialog } from './NpsDialog';
import { TagsDialog } from './TagsDialog';
import { CustomTooltip } from 'components/CustomTooltip';
import {
  NpsFilterChips,
  generateFilterChips,
} from './components/NpsFilterChips';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactsColumn: {
    textAlign: 'right',
    paddingLeft: 0,
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'left',
  },
  accordionSummaryFocused: {
    outline: `1px solid #efefef`,
  },
  boldTitle: {
    fontWeight: 500,
    padding: 0,
    clear: 'both',
    display: 'block',
  },
  dateColumn: {
    width: 110,
    textAlign: 'center',
  },
  ratingColumn: {
    width: 100,
    textAlign: 'center',
  },
  viewColumn: {
    width: 60,
    textAlign: 'center',
  },
  capitlizeText: {
    textTransform: 'capitalize',
  },
  tagChip: {
    textTransform: 'capitalize',
    margin: theme.spacing(0.5),
  },
  commentContent: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: grey[200],
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  accordion: {
    marginBottom: theme.spacing(1),
    '&::before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 'inherit',
    },
  },
  accordionDropdown: {
    padding: '0px',
  },
  cardHeader: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& .MuiCardHeader-action': {
        marginRight: 0,
        marginTop: theme.spacing(2),
        alignSelf: 'stretch',
      },
    },
  },
  headerActions: {
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',
      '& .MuiButton-root': {
        width: '100%',
      },
    },
  },
  cardTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  cardSubheader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
    },
  },
}));

interface Props {
  filteredCompanyId?: string;
  candidateUserId?: string;
}

export const NpsView: React.FC<Props> = ({ filteredCompanyId }) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<PagedNpsResponse | undefined>();

  const [leadersLoading, setLeadersLoading] = React.useState(false);
  const [leadersData, setLeadersData] = React.useState<
    NpsLeader[] | undefined
  >();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const candidateUserId = queryParams.get('candidateId') ?? undefined;

  const classes = useStyles();
  const [viewMoreDialogOpen, setViewMoreDialogOpen] = React.useState(false);
  const [selectedJob, setSelectedJob] = React.useState<Job[]>();
  const [pageNumber, setPageNumber] = React.useState(0);

  const [exporting, setExporting] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  useDocumentTitle('Net Promoter Score');

  const [submissionStatus, setSubmissionStatus] = React.useState<
    string | undefined
  >('Submitted');
  const [ratingType, setRatingType] = React.useState<string[]>([]);
  const [tierType, setTierType] = React.useState<string | undefined>(undefined);

  const [userType, setUserType] = React.useState<string[]>([]);
  const [brand, setBrand] = React.useState<string[]>([]);
  const [showTtv, setShowTtv] = React.useState(false);
  const [showCommentRecords, setShowCommentRecords] = React.useState(false);
  const [actionBy, setActionBy] = React.useState<string>();
  const [actionNeeded, setActionNeeded] = React.useState<string>();
  const [reviewee, setReviewee] = React.useState<string[]>([]);
  const [team, setTeam] = React.useState<string[]>([]);
  const [submissionType, setSubmissionType] = React.useState<
    string | undefined
  >(undefined);
  const [dateHandled, setdateHandled] = React.useState<string | undefined>(
    undefined,
  );
  const [dateFrom, setDateFrom] = React.useState<Date | undefined>(
    candidateUserId ? undefined : startOfMonth(subMonths(new Date(), 3)),
  );
  const [dateTo, setDateTo] = React.useState<Date>(new Date());

  const [textSearch, setTextSearch] = React.useState<string | undefined>();
  const debouncedTextSearch = useDebounce(textSearch, 400);

  const [npsDialogOpen, setNpsDialogOpen] = React.useState(false);
  const [tagsDialogOpen, setTagsDialogOpen] = React.useState(false);

  const handleViewMoreDialog = async (jobId: string) => {
    setDialogDataLoading(true);
    const jobDetails = await fetchJobDetails(jobId);
    setSelectedJob(jobDetails);
    setViewMoreDialogOpen(true);
    setDialogDataLoading(false);
  };

  const [dialogDataLoading, setDialogDataLoading] = React.useState(false);

  const [selectedNpsResponse, setSelectedNpsReponse] = React.useState<
    NpsResponse | undefined
  >();

  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  React.useEffect(() => {
    setSelectedNpsReponse(undefined);
  }, [setSelectedNpsReponse]);

  const fetchData = React.useCallback(() => {
    let pageNumberToLoader = pageNumber + 1;
    setLoading(true);
    const dateFromParam = !dateFrom
      ? undefined
      : format(startOfDay(dateFrom), DateTimeFormat.INPUT);
    const dateToParam = format(endOfDay(dateTo), DateTimeFormat.INPUT);
    fetchNpsResponses(
      pageNumberToLoader,
      tableSettings.rowsPerPage,
      debouncedTextSearch,
      submissionStatus,
      ratingType,
      userType,
      brand,
      reviewee,
      dateFromParam,
      dateToParam,
      filteredCompanyId,
      dateHandled !== 'All' ? dateHandled : undefined,
      actionNeeded,
      actionBy,
      team,
      submissionType,
      tierType,
      showTtv,
      candidateUserId,
      showCommentRecords,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [
    setLoading,
    setData,
    pageNumber,
    tableSettings.rowsPerPage,
    debouncedTextSearch,
    dateFrom,
    dateTo,
    brand,
    reviewee,
    submissionStatus,
    ratingType,
    userType,
    filteredCompanyId,
    dateHandled,
    actionNeeded,
    actionBy,
    submissionType,
    tierType,
    team,
    showTtv,
    candidateUserId,
    showCommentRecords,
  ]);

  const exportData = React.useCallback(async () => {
    setExporting(true);
    setSnackbarOpen(true);
    const dateFromParam = !dateFrom
      ? undefined
      : format(startOfDay(dateFrom), DateTimeFormat.INPUT);
    const dateToParam = format(endOfDay(dateTo), DateTimeFormat.INPUT);
    await exportNpsResponses(
      debouncedTextSearch,
      submissionStatus,
      ratingType,
      userType,
      brand,
      reviewee,
      dateFromParam,
      dateToParam,
      filteredCompanyId,
      dateHandled,
      actionNeeded,
      actionBy,
      team,
      submissionType,
      tierType,
      showTtv,
      candidateUserId,
      showCommentRecords,
    );
    setExporting(false);
  }, [
    debouncedTextSearch,
    dateFrom,
    dateTo,
    brand,
    reviewee,
    submissionStatus,
    ratingType,
    userType,
    filteredCompanyId,
    dateHandled,
    actionNeeded,
    actionBy,
    team,
    submissionType,
    tierType,
    showTtv,
    candidateUserId,
    showCommentRecords,
  ]);

  const user = getUserData();

  const fetchLeaders = React.useCallback(() => {
    setLeadersLoading(true);
    fetchNpsLeaders().then((d) => {
      setLeadersLoading(false);
      setLeadersData(d);
    });
  }, [setLeadersLoading, setLeadersData]);

  React.useEffect(() => {
    fetchData();
    fetchLeaders();
  }, [fetchData, fetchLeaders]);

  React.useEffect(() => {
    if (!selectedNpsResponse) {
      return;
    }
    setNpsDialogOpen(true);
  }, [selectedNpsResponse, setNpsDialogOpen]);

  React.useEffect(() => {
    if (npsDialogOpen) {
      return;
    }
    setSelectedNpsReponse(undefined);
  }, [npsDialogOpen, setSelectedNpsReponse]);

  const columns: PageableTableHeader[] = [
    {
      key: 'rating',
      label: 'Rating',
      sortable: false,
      props: {
        className: classes.ratingColumn,
      },
    },
    {
      key: 'type',
      label: 'User Type',
      sortable: false,
    },
    {
      key: 'contact',
      label: 'Contact',
      sortable: false,
    },
    {
      key: 'product',
      label: 'Product',
      sortable: false,
    },
    {
      key: 'reviewee',
      label: 'Reviewee',
      sortable: false,
    },
    {
      key: 'dateSent',
      label: 'Sent',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'dateSubmitted',
      label: 'Submitted',
      sortable: false,
      props: {
        className: classes.dateColumn,
      },
    },
    {
      key: 'comment',
      label: 'View',
      sortable: false,
      props: { className: classes.ratingColumn },
    },
  ];

  const revieweeOptions = leadersData ?? [];

  const filterChips = generateFilterChips({
    textSearch,
    submissionStatus,
    ratingType,
    userType,
    brand,
    reviewee,
    dateFrom,
    dateTo,
    dateHandled,
    actionNeeded,
    actionBy,
    team,
    submissionType,
    tierType,
    showTtv,
    showCommentRecords,
    setters: {
      setTextSearch,
      setSubmissionStatus,
      setRatingType,
      setUserType,
      setBrand,
      setReviewee,
      setDateFrom,
      setDateTo,
      setdateHandled,
      setActionNeeded,
      setActionBy,
      setTeam,
      setSubmissionType,
      setTierType,
      setShowTtv,
      setShowCommentRecords,
    },
  });

  const [filterAccordionExpanded, setFilterAccordionExpanded] =
    React.useState(false);

  return (
    <Card className={classes.card}>
      <CardHeader
        classes={{
          root: classes.cardHeader,
          title: classes.cardTitle,
          subheader: classes.cardSubheader,
        }}
        title="Net Promoter Score Submissions"
        subheader="Submissions from all Expedition42 NPS surveys."
        action={
          <div className={classes.headerActions}>
            <Button color="default" href="/dashboards#nps">
              NPS Dashboard
            </Button>
            {user?.permissions?.includes('nps') && (
              <Button
                color="secondary"
                onClick={() => {
                  setTagsDialogOpen(true);
                }}
              >
                Tag Manager
              </Button>
            )}
            <Button color="secondary" onClick={exportData} disabled={exporting}>
              Export Data
              {exporting && (
                <Box ml={1}>
                  <CircularProgress size={24} />
                </Box>
              )}
            </Button>

            {user?.permissions?.includes('nps') && (
              <Button
                color="primary"
                onClick={() => {
                  setSelectedNpsReponse(undefined);
                  setNpsDialogOpen(true);
                }}
              >
                Add NPS
              </Button>
            )}
          </div>
        }
      />

      <Accordion
        className={classes.accordion}
        elevation={0}
        expanded={filterAccordionExpanded}
        onChange={(_, expanded) => setFilterAccordionExpanded(expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ marginRight: 16 }} />}
          className={classes.accordionSummaryFocused}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ paddingLeft: 16 }}>
                Filters
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <CardContent style={{ paddingTop: 0, paddingBottom: 8 }}>
            <Grid container spacing={1}>
              {!filteredCompanyId && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  style={{ marginBottom: '16px' }}
                >
                  <TextField
                    value={textSearch}
                    variant="outlined"
                    autoFocus
                    onChange={(e) =>
                      setTextSearch(e.currentTarget.value ?? undefined)
                    }
                    fullWidth
                    placeholder="Type here to search by company, respondent, or phone number"
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={4} lg={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <DatePicker
                    label={'Date from'}
                    margin="none"
                    fullWidth
                    value={dateFrom}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: MaterialUiPickersDate) => {
                      setDateFrom(e ?? undefined);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <DatePicker
                    label={'Date to'}
                    margin="none"
                    fullWidth
                    value={dateTo}
                    inputVariant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e: MaterialUiPickersDate) => {
                      if (!e) {
                        return;
                      }
                      setDateTo(e);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    options={['Submitted', 'Unsubmitted']}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Submission Status"
                        placeholder="All Submissions"
                        {...params}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    value={submissionStatus}
                    onChange={(e, newValue) => {
                      setSubmissionStatus(newValue || undefined);
                    }}
                  />
                </FormControl>
              </Grid>
              {!filteredCompanyId && (
                <Grid item xs={12} sm={3} lg={3}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    fullWidth
                  >
                    <Autocomplete
                      options={['Yes', 'No']}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          label="Handled"
                          {...params}
                          fullWidth
                          placeholder="All Submissions"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      disableClearable={false}
                      value={dateHandled}
                      onChange={(e, newValue) => {
                        setdateHandled(newValue || undefined);
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} sm={4} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    multiple
                    options={[
                      'Admin',
                      'Candidate',
                      'Candidate-Executive',
                      'Client',
                      'Non-Admin',
                    ]}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="User Type"
                        {...params}
                        fullWidth
                        placeholder="All user types"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={true}
                    value={userType}
                    onChange={(e, newValue) => {
                      setUserType(newValue);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <Autocomplete
                    multiple
                    options={brandList}
                    getOptionLabel={(o) => o.label}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Brand"
                        {...params}
                        fullWidth
                        placeholder="All brands"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={true}
                    value={brandList.filter((b) =>
                      brand.includes(b.brand_identifier),
                    )}
                    onChange={(e, newValues) => {
                      setBrand(newValues.map((v) => v.brand_identifier));
                    }}
                  />
                </FormControl>
              </Grid>
              {!filteredCompanyId && (
                <Grid item xs={12} sm={4} lg={3}>
                  <FormControl className={classes.formControl} fullWidth>
                    <Autocomplete
                      multiple
                      options={[
                        'Generalist',
                        'IT and Gaming',
                        'Finance and Legal',
                      ]}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          label="Team"
                          {...params}
                          fullWidth
                          placeholder="All teams"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      disableClearable={true}
                      value={team}
                      onChange={(e, newValue) => {
                        setTeam(newValue);
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
              {!filteredCompanyId && (
                <Grid item xs={12} sm={4} lg={3}>
                  <FormControl className={classes.formControl} fullWidth>
                    <Autocomplete
                      multiple
                      loading={leadersLoading}
                      getOptionLabel={(o) =>
                        `${o.reviewee}${
                          o.reviewee !== 'All' ? ` (${o.team_identifier})` : ''
                        }`
                      }
                      options={revieweeOptions}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          label="Recruiter / Executive"
                          {...params}
                          fullWidth
                          placeholder="All reviewees"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                      value={revieweeOptions.filter((r) =>
                        reviewee.includes(r.reviewee),
                      )}
                      onChange={(e, newValue) => {
                        setReviewee(newValue.map((v) => v.reviewee));
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={3} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    options={['Yes', 'No', 'Missing Tags']}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Action Needed"
                        {...params}
                        fullWidth
                        placeholder="All Action Types"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={false}
                    value={actionNeeded}
                    onChange={(e, newValue) => {
                      setActionNeeded(newValue || undefined);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    getOptionLabel={(o) =>
                      `${o.reviewee}${
                        o.reviewee !== 'All' ? ` (${o.team_identifier})` : ''
                      }`
                    }
                    options={revieweeOptions}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Action By"
                        {...params}
                        fullWidth
                        placeholder="All reviewees"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={true}
                    value={revieweeOptions.find((f) => f.reviewee === actionBy)}
                    onChange={(e, newValue) => {
                      setActionBy(newValue.reviewee);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    options={['Manual Submission', 'User Submission']}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Submission Type"
                        {...params}
                        fullWidth
                        placeholder="All submission types"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={false}
                    value={submissionType ?? ''}
                    onChange={(e, newValue) => {
                      setSubmissionType(newValue || undefined);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} lg={3}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    multiple
                    options={['Promoter', 'Neutral', 'Detractor']}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Rating Type"
                        placeholder="All Rating types"
                        {...params}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    disableClearable={true}
                    value={ratingType}
                    onChange={(e, newValue) => {
                      setRatingType(newValue);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showCommentRecords}
                      onChange={(e, checked) => {
                        setShowCommentRecords(checked);
                      }}
                    />
                  }
                  label={'Only show records with comments'}
                />
              </Grid>
              {(brand.includes('tlx') || brand.includes('talexio')) && (
                <Grid item sm={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showTtv}
                        onChange={(e, checked) => {
                          setShowTtv(checked);
                        }}
                      />
                    }
                    label={'Only show Team Voice Submissions'}
                  />
                </Grid>
              )}
              {(brand.includes('tlx') || brand.includes('talexio')) && (
                <Grid item sm={3}>
                  <Autocomplete
                    options={['Free Users', 'Paid Users']}
                    renderInput={(params) => (
                      <TextField
                        variant="outlined"
                        label="Subscription Type"
                        placeholder="All Subscription types"
                        {...params}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    value={tierType ?? ''}
                    onChange={(e, newValue) => {
                      setTierType(newValue || undefined);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </AccordionDetails>
      </Accordion>

      {!filterAccordionExpanded && <NpsFilterChips filters={filterChips} />}

      <PageableTable
        tableSettings={tableSettings}
        setTableSettings={setTableSettings}
        showSkeletonLoading
        rows={
          data?.data?.map((d, index) => ({
            key: index.toString(),
            cells: [
              {
                key: 'rating',
                props: {
                  className: classes.ratingColumn,
                },
                display: (
                  <div
                    style={
                      d.rating_type
                        ? {
                            color:
                              d.rating_type === 'promoter'
                                ? green[600]
                                : d.rating_type === 'detractor'
                                ? red[600]
                                : 'inherit',
                          }
                        : undefined
                    }
                  >
                    {d.rating !== null && (
                      <Typography variant="h6" style={{ lineHeight: 1 }}>
                        {d.rating}
                      </Typography>
                    )}
                    {d.rating_type && (
                      <Typography
                        variant="caption"
                        className={classes.capitlizeText}
                      >
                        {d.rating_type}
                      </Typography>
                    )}
                  </div>
                ),
              },
              {
                key: 'type',
                display: (
                  <React.Fragment>
                    {d.user_type && (
                      <Typography
                        variant="body1"
                        className={classes.capitlizeText}
                      >
                        {d.user_type}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      className={classes.capitlizeText}
                    >
                      {resolvePlatformName(d.brand_identifier ?? '').replaceAll(
                        'Konnekt ATS',
                        'Konnekt',
                      )}
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                key: 'contact',
                display: (
                  <React.Fragment>
                    <Button
                      className={classNames(
                        d.user_id
                          ? [classes.boldTitle, classes.clickable]
                          : [classes.boldTitle],
                      )}
                      variant="text"
                      style={{ textAlign: 'left' }}
                      onClick={() => {
                        if (d.user_id) {
                          if (d.brand_identifier === 'knt') {
                            if (
                              d.user_type === 'candidate' ||
                              d.user_type === 'candidate-executive'
                            ) {
                              window.open(
                                `https://exp42.talexiohr.com/ats/candidates/${d.user_id}`,
                              );
                            } else if (d.user_type === 'client') {
                              window.open(
                                `https://exp42.talexiohr.com/ats/companies/${d.user_id}`,
                              );
                            }
                          }
                        }
                      }}
                    >
                      {d.user_first_name} {d.user_last_name}
                    </Button>

                    {d.company && (
                      <div>
                        <Button
                          className={classNames(
                            d.company_id
                              ? [classes.boldTitle, classes.clickable]
                              : [classes.boldTitle],
                          )}
                          variant="text"
                          onClick={
                            d.company_id
                              ? () => {
                                  window.open(`/clients/${d.company_id}`);
                                }
                              : () => {}
                          }
                        >
                          {d.company}
                        </Button>
                        {d.blacklisted && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Flag
                              style={{
                                color: 'black',
                                fontSize: '1.5em',
                              }}
                            />
                            <CustomTooltip
                              titleHeader="Listing Reasons"
                              bodyText={d.blacklisted_reasons}
                              placement="top"
                              maxWidth={600}
                              enabledArrow={true}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  marginLeft: '10px',
                                  borderBottom: '1px dotted',
                                  cursor: 'pointer',
                                }}
                              >
                                Blacklisted by:{' '}
                                {d.blacklisted
                                  .slice(1, -1)
                                  .replace(/'/g, '')
                                  .split(',')
                                  .map((item) => item.trim())
                                  .join(', ')}
                              </Typography>
                            </CustomTooltip>
                          </div>
                        )}
                        {d.greylisted && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <Flag
                              style={{
                                color: 'grey',
                                fontSize: '1.5em',
                              }}
                            />
                            <CustomTooltip
                              titleHeader="Listing Reasons"
                              bodyText={d.blacklisted_reasons}
                              placement="top"
                              maxWidth={600}
                              enabledArrow={true}
                            >
                              <Typography
                                variant="caption"
                                style={{
                                  marginLeft: '10px',
                                  borderBottom: '1px dotted',
                                  cursor: 'pointer',
                                }}
                              >
                                Greylisted by:{' '}
                                {d.greylisted
                                  .slice(1, -1)
                                  .replace(/'/g, '')
                                  .split(',')
                                  .map((item) => item.trim())
                                  .join(', ')}
                              </Typography>
                            </CustomTooltip>
                          </div>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ),
              },

              {
                key: 'product',
                display: (
                  <React.Fragment>
                    {(() => {
                      if (d.product_name) {
                        let productName, statusLabel;

                        if (
                          d.product_name.includes('(') &&
                          d.product_name.includes(')')
                        ) {
                          productName = d.product_name.substring(
                            0,
                            d.product_name.lastIndexOf(' ('),
                          );
                          let potentialStatus = d.product_name.substring(
                            d.product_name.lastIndexOf(' (') + 2,
                            d.product_name.length - 1,
                          );
                          const statuses = [
                            'Successful',
                            'Position Shelved',
                            'Unsuccessful',
                          ];
                          statusLabel = statuses.includes(potentialStatus)
                            ? potentialStatus
                            : '';
                        } else {
                          productName = d.product_name
                            .split(',')
                            .filter((name) => name !== 'support')
                            .map((name) =>
                              name
                                .split('_')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() +
                                    word.slice(1),
                                )
                                .join(' '),
                            )
                            .join(', ')
                            .replace('Hr ', 'HR ');
                        }

                        let productContent = d.product_id ? (
                          <Button
                            className={classNames([classes.boldTitle], {
                              [classes.clickable]: d.product_id,
                            })}
                            onClick={() =>
                              d.product_id && handleViewMoreDialog(d.product_id)
                            }
                            style={{ cursor: 'pointer' }}
                            color="default"
                          >
                            <Typography variant="body1">
                              {productName}
                            </Typography>
                          </Button>
                        ) : (
                          <Typography variant="body1">{productName}</Typography>
                        );

                        return (
                          <React.Fragment>
                            {productContent}
                            {statusLabel && (
                              <Typography variant="caption">
                                {statusLabel}
                              </Typography>
                            )}
                          </React.Fragment>
                        );
                      } else {
                        return <Typography variant="body1">N/A</Typography>;
                      }
                    })()}
                  </React.Fragment>
                ),
              },

              {
                key: 'reviewee',
                display: (
                  <React.Fragment>
                    {d.reviewee ? (
                      <Typography variant="body1">{d.reviewee}</Typography>
                    ) : (
                      <Typography variant="body1">N/A</Typography>
                    )}
                    {d.team_identifier && (
                      <Typography
                        variant="caption"
                        className={classes.capitlizeText}
                      >
                        {d.team_identifier}
                      </Typography>
                    )}
                  </React.Fragment>
                ),
              },
              {
                key: 'dateSent',
                display:
                  d.date_sent && d.date_sent !== 'NaT' ? (
                    <DateDisplay date={d.date_sent} />
                  ) : (
                    <Typography variant="body1">N/A</Typography>
                  ),
                props: {
                  className: classes.dateColumn,
                },
              },
              {
                key: 'dateSubmitted',
                display: d.date_submitted && d.date_submitted !== 'NaT' && (
                  <DateDisplay date={d.date_submitted} />
                ),
                props: {
                  className: classes.dateColumn,
                },
              },
              {
                key: 'comment',
                display: (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Badge
                      badgeContent={
                        (d.comment_count || 0) >= 1 ? d.comment_count : 0
                      }
                      color={
                        (d.comment_count || 0) >= 1 ? 'secondary' : 'default'
                      }
                    >
                      <RemoveRedEye
                        onClick={() => {
                          setSelectedNpsReponse(d);
                        }}
                        style={{
                          cursor: 'pointer',
                          color:
                            (d.comment_count || 0) >= 1 ? '#2e76ab' : 'inherit',
                        }}
                      >
                        <Comment />
                      </RemoveRedEye>
                    </Badge>
                  </Box>
                ),
              },
            ],
          })) ?? []
        }
        loading={loading}
        pageNumber={pageNumber}
        rowCount={data?.total_count ?? 0}
        columns={columns}
        onChangePage={setPageNumber}
      />
      <NpsDialog
        open={npsDialogOpen}
        defaultCompanyId={filteredCompanyId}
        selectedNpsResponseId={selectedNpsResponse?.platform_nps_id}
        onClose={() => {
          setNpsDialogOpen(false);
        }}
      />
      <TagsDialog
        open={tagsDialogOpen}
        onClose={() => {
          setTagsDialogOpen(false);
        }}
      />
      <JobDetailsDialog
        open={viewMoreDialogOpen}
        job={selectedJob!}
        loading={dialogDataLoading}
        onClose={() => setViewMoreDialogOpen(false)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="info">
          Exporting data, your download will start shortly.
        </Alert>
      </Snackbar>
    </Card>
  );
};
