export interface Relation {
  company_id: string;
  company_title: string;
  hierarchy_level: number;
  direct_parent_id: string;
  direct_parent_name: string;
}

export interface RelationNode extends Relation {
  children: RelationNode[];
}

export interface PagedRelations {
  total_count: number;
  data: Relation[];
}

export function buildRelationTree(relations: Relation[]): RelationNode[] {
  const nodeMap = new Map<string, RelationNode>();
  const rootNodes: RelationNode[] = [];

  relations.forEach((relation) => {
    nodeMap.set(relation.company_id, { ...relation, children: [] });
  });

  relations.forEach((relation) => {
    const node = nodeMap.get(relation.company_id)!;
    if (relation.direct_parent_id && nodeMap.has(relation.direct_parent_id)) {
      const parentNode = nodeMap.get(relation.direct_parent_id)!;
      parentNode.children.push(node);
    } else {
      rootNodes.push(node);
    }
  });

  return rootNodes;
}
