// lib/Api/QuoteGenerator.ts (Modified)
import {
  QuoteResponseLineItem,
  QuoteResponse,
  QuoteRequest,
  PricingTier,
} from 'lib/Model/QuoteGenerator';
import pricingData from './data/pricing.json';

export function determineTierSize(companySize: number): string {
  const tiers = pricingData.tiers;
  if (
    companySize >= tiers.tier_1.minEmployees &&
    companySize <= tiers.tier_1.maxEmployees
  )
    return 'tier_1';
  if (
    companySize >= tiers.tier_2.minEmployees &&
    companySize <= tiers.tier_2.maxEmployees
  )
    return 'tier_2';
  if (
    companySize >= tiers.tier_3.minEmployees &&
    companySize <= tiers.tier_3.maxEmployees
  )
    return 'tier_3';
  if (
    companySize >= tiers.tier_4.minEmployees &&
    companySize <= tiers.tier_4.maxEmployees
  )
    return 'tier_4';
  return 'Unknown';
}

export function generateQuote(request: QuoteRequest): QuoteResponse {
  const effectiveCompanySize = Math.max(request.companySize, 10);
  const tierKey = determineTierSize(effectiveCompanySize) as PricingTier;

  const CORE_HARDWARE_KEYS = new Set([
    'uA760',
    'silkBio',
    'shared_team_virtual_punchclock',
    'rfid_em_cards',
  ]);

  let modulesItems: QuoteResponseLineItem[] = [];
  let hardwareItems: QuoteResponseLineItem[] = [];
  let extrasItems: QuoteResponseLineItem[] = [];
  let monthlyTotal = 0;
  let setupTotal = 0;
  let unitPriceTotal = 0;

  for (const module of request.modules) {
    const isHardware = CORE_HARDWARE_KEYS.has(module.moduleKey);

    const userQty =
      module.userQty !== undefined
        ? module.userQty
        : isHardware
        ? 1
        : module.moduleKey === 'csp_per_payslip'
        ? 0 // csp needs explicit quantity like t&a
        : effectiveCompanySize;

    const modulePricing = pricingData.pricing[tierKey];
    const addon = modulePricing.addons.find(
      (a) => a.module === module.moduleKey,
    );

    if (addon) {
      if (isHardware) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const isVirtualPunchclock =
          module.moduleKey === 'shared_team_virtual_punchclock';
        const hardwarePrice =
          addon.clocking_pro_price ?? addon.clocking_price ?? addon.price ?? 0;

        if (isVirtualPunchclock) {
          const monthlyPrice = (addon.price ?? 0) * userQty;
          hardwareItems.push({
            moduleKey: module.moduleKey,
            userQty,
            price: monthlyPrice,
            originalPrice: addon.price, // Store original price
            discountUnitPrice: 0, // No discount on virtual punchclock unit price
          });
          monthlyTotal += monthlyPrice;
          setupTotal += (addon.setup_price ?? 0) * userQty;
          unitPriceTotal += addon.price ?? 0; // Add the €15 to unit price total
        } else {
          // Other hardware items

          const effectiveHardwarePrice = Math.max(
            hardwarePrice - discountUnitPrice,
            0,
          );
          const cost = effectiveHardwarePrice * userQty;

          hardwareItems.push({
            moduleKey: module.moduleKey,
            userQty,
            price: cost,
            originalPrice: hardwarePrice, // Store original price
            discountUnitPrice, // Store discount
          });

          // hardware setup fees
          if (addon.setup_price) {
            setupTotal += addon.setup_price * userQty;
          }

          // hardware price is OT
          setupTotal += cost;
        }
      } else {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const discountSetupFee = module.discountSetupFee ?? 0;
        const originalPrice = addon.price ?? 0; // Get original price
        const originalSetupFee = addon.setup_price ?? 0; // Get original setup fee
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );

        const modulePrice = effectivePrice * userQty;

        modulesItems.push({
          moduleKey: module.moduleKey,
          userQty: userQty,
          price: modulePrice,
          originalPrice, // Store original price
          discountUnitPrice, // Store discount
          originalSetupFee, // Store original setup fee
          discountSetupFee, // Store setup fee discount
        });
        monthlyTotal += modulePrice;
        setupTotal += effectiveSetupPrice;
        unitPriceTotal += effectivePrice;
      }
    }

    const extra = pricingData.extras.find((e) => e.key === module.moduleKey);
    if (extra) {
      const originalPrice = extra.price ?? 0;
      const originalSetupFee = extra.setup_price ?? 0;

      if (module.moduleKey === 'signrequest' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: price,
          originalPrice,
          discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      } else if (module.moduleKey === 'reporting_database' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: price,
          originalPrice,
          discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      } else if (
        module.moduleKey === 'parallel_payroll_runs' &&
        module.userQty
      ) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: 0,
          originalSetupFee,
          discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'data_migration' && module.userQty) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;

        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: 0,
          originalSetupFee: originalSetupFee,
          discountSetupFee: discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'onboarding_session' && module.userQty) {
        const discountSetupFee = module.discountSetupFee ?? 0;
        const effectiveSetupPrice = Math.max(
          originalSetupFee - discountSetupFee,
          0,
        );
        const setupPrice = effectiveSetupPrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: setupPrice,
          originalSetupFee: originalSetupFee,
          discountSetupFee,
        });
        setupTotal += setupPrice;
      } else if (module.moduleKey === 'visitor_management' && module.userQty) {
        const discountUnitPrice = module.discountUnitPrice ?? 0;
        const effectivePrice = Math.max(originalPrice - discountUnitPrice, 0);
        const price = effectivePrice * module.userQty;
        extrasItems.push({
          moduleKey: module.moduleKey,
          userQty: module.userQty,
          price: price,
          originalPrice,
          discountUnitPrice,
        });
        monthlyTotal += price;
        unitPriceTotal += effectivePrice;
      }
    }
  }

  return {
    companyName: request.companyName,
    modules: modulesItems,
    hardware: hardwareItems,
    extras: extrasItems,
    monthlyFee: monthlyTotal,
    annualFee: monthlyTotal * 12,
    oneTimeFee: setupTotal,
    totalUnitPrice: unitPriceTotal,
  };
}
