import { Hidden, IconButton, makeStyles, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import classNames from 'classnames';
import { usePrevious } from 'hooks';
import { checkUser } from 'lib/Api/User';
import { getUserData } from 'lib/Helper/Auth';
import * as React from 'react';
import {
  BreadcrumbsItem,
  BreadcrumbsProvider,
} from 'react-breadcrumbs-dynamic';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ClientsView } from 'views';
import QuoteGeneratorView from 'views/QuoteGenerator/QuoteGeneratorView';
import CommercialSupportView from 'views/ClientAllocation/ClientAllocationView';
import { DashboardView } from 'views/Dashboard/DashboardView';
import { DashboardsView } from 'views/Dashboards/DashboardsView';
import { MeetingsView } from 'views/Meetings';
import { NpsView } from 'views/NPS';
import { SpreadsheetsView } from 'views/Spreadsheets';
import { Breadcrumbs, Sidebar, UpdateNotification } from './components';
import ChatbotView from 'views/Chatbot/ChatbotView';
import HelpbotView from 'views/Helpbot/HelpbotView';
interface Props {
  componentError?: Error;

  waitingServiceWorker: ServiceWorker | null;
  updateServiceWorker(worker: ServiceWorker | null, fromBanner: boolean): void;
}

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100%',
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.drawerWidth,
      paddingBottom: theme.spacing(5),
    },
  },
  trialMain: {
    opacity: 0.2,
  },
  noSidebar: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    },
  },
  content: {
    ...theme.mixins.gutters(),
    paddingBottom: theme.spacing(8.5),
  },
  loadingWrapper: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  offsetTrialBanner: {
    paddingBottom: theme.spacing(7.5),

    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  offsetSampleDataTB: {
    paddingBottom: theme.spacing(7.5),

    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(15),
    },
  },
  menuButton: {
    marginLeft: theme.spacing(1) * -1,
    marginRight: theme.spacing(0.5),
  },
  headerToolbar: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 0,
  },
}));

export const AppLayout: React.FC<Props> = ({
  waitingServiceWorker,
  updateServiceWorker,
}) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { search } = useLocation();

  const [redirectAfterLogin, setRedirectAfterLogin] = React.useState<
    string | undefined
  >();

  const authUser = getUserData();
  const prevToken = usePrevious(authUser?.token);

  const iFrame =
    new URLSearchParams(useLocation().search).get('iframe') === '1'
      ? true
      : false;

  React.useEffect(() => {
    if (authUser && authUser.token !== prevToken) {
      checkUser(authUser.token);
    }
  }, [authUser, prevToken]);

  React.useEffect(() => {
    if (!redirectAfterLogin || redirectAfterLogin === '/dashboard') {
      const urlSearchParams = new URLSearchParams(search);
      const prevRedirect = urlSearchParams.get('r');
      const prevQueryParams = urlSearchParams.get('q');

      if (prevRedirect && prevRedirect.trim().length > 0) {
        setRedirectAfterLogin(
          (prevRedirect === '/' || !prevRedirect
            ? '/dashboard'
            : prevRedirect) +
            (prevQueryParams ? decodeURIComponent(prevQueryParams) : ''),
        );
      }
    } else if (!redirectAfterLogin) {
      setRedirectAfterLogin('/dashboard');
    }
  }, [redirectAfterLogin, search]);

  return (
    <React.Fragment>
      <Sidebar
        open={drawerOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
      />

      <BreadcrumbsProvider>
        <main
          id="main"
          className={classNames({
            [classes.main]: true,
            iframe: iFrame,
          })}
        >
          <Toolbar className={classes.headerToolbar}>
            <Hidden lgUp>
              <IconButton
                color="inherit"
                className={classes.menuButton}
                onClick={toggleDrawer}
              >
                <Menu />
              </IconButton>
            </Hidden>
            <Breadcrumbs />
          </Toolbar>
          {Boolean(waitingServiceWorker) && (
            <UpdateNotification
              updateServiceWorker={() =>
                updateServiceWorker(waitingServiceWorker, true)
              }
            />
          )}

          <Switch>
            {' '}
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard">
              <BreadcrumbsItem to="/dashboard">Dashboard</BreadcrumbsItem>
              <DashboardView />
            </Route>
            <Route path="/clients">
              <BreadcrumbsItem to="/clients">Clients</BreadcrumbsItem>
              <ClientsView />
            </Route>
            <Route path="/chatbot">
              <BreadcrumbsItem to="/chatbot">AI Assistant</BreadcrumbsItem>
              <ChatbotView />
            </Route>
            <Route path="/help">
              <BreadcrumbsItem to="/help">Help Bot</BreadcrumbsItem>
              <HelpbotView />
            </Route>
            <Route path="/dashboards">
              <BreadcrumbsItem to="/dashboards">Dashboards</BreadcrumbsItem>
              <DashboardsView />
            </Route>
            <Route path="/spreadsheets">
              <BreadcrumbsItem to="/spreadsheets">
                Resources & Spreadsheets
              </BreadcrumbsItem>
              <SpreadsheetsView />
            </Route>
            <Route path="/nps">
              <BreadcrumbsItem to="/nps">NPS</BreadcrumbsItem>
              <NpsView />
            </Route>
            <Route path="/meetings">
              <BreadcrumbsItem to="/meetings">Meetings</BreadcrumbsItem>
              <MeetingsView />
            </Route>
            <Route path="/quote-generator">
              <BreadcrumbsItem to="/quote-generator">
                Quote Generator
              </BreadcrumbsItem>
              <QuoteGeneratorView />
            </Route>
            <Route path="/commercial-support-utility">
              <BreadcrumbsItem to="/commercial-support-utility">
                Commercial Support
              </BreadcrumbsItem>
              <CommercialSupportView />
            </Route>
            {redirectAfterLogin && (
              <Route>
                <Redirect to={redirectAfterLogin ?? '/dashboard'} />
              </Route>
            )}
          </Switch>
          <div style={{ height: 20 }} />
        </main>
      </BreadcrumbsProvider>
    </React.Fragment>
  );

  function toggleDrawer() {
    setDrawerOpen((open) => !open);
  }

  function openDrawer() {
    if (!drawerOpen) {
      toggleDrawer();
    }
  }

  function closeDrawer() {
    if (drawerOpen) {
      toggleDrawer();
    }
  }
};
