import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { EmptyView } from 'components/EmptyView';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { fetchRelationsForCompany } from 'lib/Api/Matches';
import { stringAvatar } from 'lib/Helper/Avatars';
import {
  PagedRelations,
  Relation,
  buildRelationTree,
  RelationNode,
} from 'lib/Model/Relation';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactColumn: {},
  actionsColumn: {
    width: 80,
    textAlign: 'right',
    paddingLeft: 0,
  },
  avatarColumn: {
    width: 30,
    paddingRight: 0,
    verticalAlign: 'top',
  },
  smallAvatar: {
    width: 26,
    height: 26,
    fontSize: 14,
  },
  boldTitle: {
    fontWeight: 500,
  },
  hierarchyLine: {
    position: 'relative',
    height: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -theme.spacing(2),
      top: '50%',
      width: theme.spacing(2),
      height: 1,
      backgroundColor: theme.palette.divider,
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: -theme.spacing(2),
      top: -theme.spacing(2),
      bottom: '50%',
      width: 1,
      backgroundColor: theme.palette.divider,
    },
  },
  hierarchyContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  nodeContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  treeContainer: {
    padding: theme.spacing(2),
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  skeletonContainer: {
    padding: theme.spacing(2),
  },
  skeletonRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  skeletonAvatar: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  companyId: string;
  doCompanyRefresh(): void;
}

const RelationRow: React.FC<{
  node: RelationNode;
  classes: any;
  theme: any;
  onOpenDetail: (d: Relation) => void;
}> = ({ node, classes, theme, onOpenDetail }) => {
  return (
    <>
      <div
        className={classes.hierarchyContainer}
        style={{
          paddingLeft: `${theme.spacing(4) * node.hierarchy_level}px`,
        }}
      >
        <div
          className={
            node.hierarchy_level > 0 ? classes.hierarchyLine : undefined
          }
        >
          <div className={classes.nodeContent}>
            <div className={classes.avatarColumn}>
              <Avatar
                variant="rounded"
                {...stringAvatar(node.company_title)}
                className={classes.smallAvatar}
              />
            </div>
            <div style={{ marginLeft: theme.spacing(1) }}>
              <Button
                variant="text"
                color="primary"
                style={{ padding: 0, textAlign: 'left' }}
                onClick={() => onOpenDetail(node)}
              >
                {node.company_title}
              </Button>
              {node.hierarchy_level > 0 && (
                <Typography variant="caption" style={{ display: 'block' }}>
                  Parent Company: {node.direct_parent_name}
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
      {node.children.map((child, index) => (
        <RelationRow
          key={child.company_id}
          node={child}
          classes={classes}
          theme={theme}
          onOpenDetail={onOpenDetail}
        />
      ))}
    </>
  );
};

export const RelatedSuggestions: React.FC<Props> = ({ companyId }) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber] = React.useState(0);

  const { pathname, hash } = useLocation();
  const [tableSettings] = useLocalStorageSettings<TableSettings>(
    getTableSettingsStorageKey(pathname, hash),
    { rowsPerPage: 25 },
  );

  const [data, setData] = React.useState<PagedRelations | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchRelationsForCompany(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [setLoading, setData, pageNumber, tableSettings.rowsPerPage, companyId]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const theme = useTheme();

  function onOpenDetail(d: Relation) {
    window.open(`/clients/${d.company_id}`);
  }

  const renderSkeletonLoading = () => {
    return (
      <div className={classes.skeletonContainer}>
        {[...Array(5)].map((_, i) => (
          <div key={i} className={classes.skeletonRow}>
            <Skeleton
              variant="circle"
              width={26}
              height={26}
              className={classes.skeletonAvatar}
            />
            <Skeleton variant="text" width={`${Math.random() * 30 + 20}%`} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Affiliated Companies"
        subheader="Companies that are related to each other. These company family trees are built on Zendesk Sell."
      />
      <Divider />
      <div className={classes.treeContainer}>
        {loading ? (
          renderSkeletonLoading()
        ) : !data?.data?.length ? (
          <EmptyView>
            No affiliated companies found.
            <br />
            This company is not part of any company family tree in Zendesk Sell.
          </EmptyView>
        ) : (
          data.data &&
          buildRelationTree(data.data).map((node, index) => (
            <RelationRow
              key={node.company_id}
              node={node}
              classes={classes}
              theme={theme}
              onOpenDetail={onOpenDetail}
            />
          ))
        )}
      </div>
    </Card>
  );
};
