import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { Undo } from '@material-ui/icons';
import { PageableTable, SnackbarContext } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateTimeFormat } from 'config';
import { format, parseISO } from 'date-fns';
import {
  deleteMerge,
  fetchMergesForCompany,
  exportIntramerges,
} from 'lib/Api/Matches';
import { getUserData } from 'lib/Helper/Auth';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedMerges } from 'lib/Model/Merge';
import * as React from 'react';
import { resolvePlatformName } from './SuggestedMatches';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  contactColumn: {},
  actionsColumn: {
    width: 80,
    textAlign: 'right',
    paddingLeft: 0,
  },
  avatarColumn: {
    width: 30,
    paddingRight: 0,
    verticalAlign: 'top',
    paddingTop: theme.spacing(3),
  },
  smallAvatar: {
    width: 26,
    height: 26,
    fontSize: 14,
  },
  boldTitle: {
    fontWeight: 500,
  },
}));

interface Props {
  companyId: string;
  doCompanyRefresh(): void;
}

export const MergedCompanies: React.FC<Props> = ({
  companyId,
  doCompanyRefresh,
}) => {
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const [pageNumber, setPageNumber] = React.useState(0);
  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );
  const [exporting, setExporting] = React.useState(false);
  const [confirmUnmergeOpen, setConfirmUnmergeOpen] = React.useState(false);
  const [selectedMerge, setSelectedMerge] = React.useState<any>(null);

  const snackbar = React.useContext(SnackbarContext);
  const [data, setData] = React.useState<PagedMerges | undefined>();

  const fetchData = React.useCallback(() => {
    setLoading(true);
    fetchMergesForCompany(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
    ).then((d) => {
      setLoading(false);
      setData(d);
    });
  }, [setLoading, setData, pageNumber, tableSettings.rowsPerPage, companyId]);

  const user = getUserData();
  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: PageableTableHeader[] = [
    {
      key: 'id',
      label: '',
      sortable: false,
    },
    {
      key: 'contact',
      label: 'Contact',
      sortable: false,
    },
    {
      key: 'actions',
      label: '',
      sortable: false,
    },
  ];

  const handleExportClick = async () => {
    setExporting(true);
    try {
      await exportIntramerges();
      snackbar.success('Export successful.');
    } catch (error) {
      snackbar.error('Export failed!');
    }
    setExporting(false);
  };

  const handleUnmerge = async (mergeData: any) => {
    setSelectedMerge(mergeData);
    setConfirmUnmergeOpen(true);
  };

  const handleConfirmUnmerge = async () => {
    if (!selectedMerge) return;

    setLoading(true);
    try {
      await deleteMerge(companyId, selectedMerge.merge_id.toString());
      snackbar.success('Companies unmerged successfully.');
      doCompanyRefresh();
    } catch (ex) {
      snackbar.error(`Failed to unmerge companies: ${ex}`);
    } finally {
      setLoading(false);
      setConfirmUnmergeOpen(false);
      setSelectedMerge(null);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Card className={classes.card}>
            <CardHeader
              title="Merged companies"
              subheader="Accepted suggestions indicating that the companies are the same."
              action={
                <Button
                  color="secondary"
                  onClick={handleExportClick}
                  disabled={exporting || loading}
                >
                  Export Merges Data
                  {exporting && (
                    <Box ml={1}>
                      <CircularProgress size={24} />
                    </Box>
                  )}
                </Button>
              }
            />
            <Divider />
            <PageableTable
              tableSettings={tableSettings}
              setTableSettings={setTableSettings}
              showSkeletonLoading
              hideColumns
              rows={
                data?.data?.map((d, index) => ({
                  key: index.toString(),
                  cells: [
                    {
                      key: 'id',
                      display: d.company_title && (
                        <Avatar
                          variant="rounded"
                          {...stringAvatar(d.company_title)}
                          className={classes.smallAvatar}
                        />
                      ),
                      props: {
                        className: classes.avatarColumn,
                      },
                    },
                    {
                      key: 'contact',
                      display: (
                        <React.Fragment>
                          <Typography className={classes.boldTitle}>
                            {d.company_title}
                          </Typography>
                          <Typography variant="caption">
                            {resolvePlatformName(d.alias_id)}
                          </Typography>
                          <div>
                            <Typography variant="caption">
                              {d.c_number &&
                                `Registration Number: ${d.c_number}`}
                            </Typography>
                          </div>
                          {d.created_by && (
                            <div>
                              <Typography variant="caption">
                                Merged by {d.created_by}
                                {d.created_on
                                  ? ` on ${format(
                                      parseISO(d.created_on),
                                      DateTimeFormat.LONG,
                                    )}`
                                  : null}
                              </Typography>
                            </div>
                          )}
                        </React.Fragment>
                      ),
                    },
                    {
                      key: 'actions',
                      display: user?.permissions?.includes('merge') && (
                        <ButtonGroup disableElevation>
                          <Tooltip title="Unmerge">
                            <Button
                              size="small"
                              style={{
                                color: red[500],
                              }}
                              onClick={() => handleUnmerge(d)}
                            >
                              <Undo />
                            </Button>
                          </Tooltip>
                        </ButtonGroup>
                      ),
                      props: {
                        className: classes.actionsColumn,
                      },
                    },
                  ],
                })) ?? []
              }
              loading={loading}
              pageNumber={pageNumber}
              rowCount={data?.total_count ?? 0}
              columns={columns}
              onChangePage={setPageNumber}
            />
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={confirmUnmergeOpen}
        onClose={() => setConfirmUnmergeOpen(false)}
      >
        <DialogTitle>Confirm Unmerge</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to unmerge {selectedMerge?.company_title}?
            This action will separate the companies. Any data related to{' '}
            {resolvePlatformName(selectedMerge?.alias_id)} will be removed from{' '}
            {selectedMerge?.company_title}.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmUnmergeOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmUnmerge}
            color="secondary"
            disabled={loading}
            startIcon={loading && <CircularProgress size={20} />}
          >
            Confirm Unmerge
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
