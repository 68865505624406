import {
  Avatar,
  Card,
  CardContent,
  Chip,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { grey, red } from '@material-ui/core/colors';
import { Folder } from '@material-ui/icons';
import { useDebounce } from 'hooks';
import { fetchClients } from 'lib/Api/Clients';
import { stringAvatar } from 'lib/Helper/Avatars';
import { PagedClients } from 'lib/Model/Client';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  resolveMergedAliasesToPlatformNames,
  resolvePlatformName,
} from 'views/Clients/components/SuggestedMatches';
import { ActivityFeed } from './components/ActivityFeed';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  clickableListItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  itemIconImg: {
    width: 32,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  sectionHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      margin: '12px 0',
    },
  },
  searchResultList: {
    position: 'relative',
    top: 'calc(100% + 8px)',
    left: 0,
    right: 0,
    maxWidth: 600,
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    zIndex: 1000,
  },
  warningCard: {
    backgroundColor: red[50],
    borderLeft: `4px solid ${red[500]}`,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 1,
  },
  toolCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    cursor: 'pointer',
    height: '100%',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: grey[50],
    },
  },
  toolIcon: {
    width: 40,
    height: 40,
    marginBottom: theme.spacing(0.5),
  },
  toolTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  sectionTitle: {
    padding: theme.spacing(1.5, 2, 0.5),
    fontSize: '1rem',
  },
}));

interface Shortcut {
  link: string;
  title: string;
  description: string;
  icon?: string;
  divider?: boolean;
}

const shortcuts: Shortcut[] = [
  {
    link: 'https://exp42.talexiohr.com',
    title: 'Talexio HR',
    description: 'Manage your personal details, book leave and more.',
    icon: '/talexio.png',
  },
  {
    link: 'https://exp42.talexiohr.com/ats/dashboard',
    title: 'Konnekt ATS',
    description:
      'Our applicant tracking system with candidates, vacancies and clients.',
    icon: '/konnekt.png',
  },
  {
    link: 'https://app.clickup.com/43267036/home',
    title: 'ClickUp',
    description: 'Our project management tool.',
    icon: '/clickup.png',
  },
  {
    link: 'https://chat.exp42.com/',
    title: 'Mattermost',
    description: 'Our internal chat platform.',
    icon: '/mattermost.png',
  },
  {
    link: 'https://talexiohr.zendesk.com/agent/',
    title: 'Zendesk Support',
    description:
      'Receive and action support tickets submitted from users of all our systems.',
    icon: '/zendesk_support.png',
  },
  {
    link: 'https://app.futuresimple.com/crm/',
    title: 'Zendesk Sell',
    description: 'Manage incoming leads, clients and proposals.',
    icon: '/zendesk_sell.png',
  },
  {
    link: 'https://integratedtalent-company.monday.com/',
    title: 'Monday.com',
    description: 'Our project management tool for the Payroll Malta team.',
    icon: '/monday.svg',
  },
  {
    link: 'https://preeosoftware.atlassian.net/',
    title: 'JIRA',
    description: 'Keep track of development tasks and issues.',
    icon: '/jira.ico',
  },
  {
    link: 'https://drive.google.com/drive/folders/0ANqsmV1Ux7tQUk9PVA',
    title: 'Client Drive',
    description:
      'Our Google Drive folder for client agreements and meeting notes.',
    icon: '/Google_Drive.png',
  },
  {
    link: 'https://exp42.signrequest.com/',
    title: 'SignRequest',
    description:
      'Quickly sign and send terms of business and agreements to clients.',
    icon: '/signrequest.png',
  },
  {
    link: 'https://registry.mbr.mt/ROC/index.jsp#companySearch.do?action=companyDetails',
    title: 'Malta Business Registry',
    description: 'Find information on companies listed on the MBR.',
    icon: '/roca-logo.png',
  },
  {
    link: 'https://vat-search.eu/search/mt',
    title: 'VAT Search',
    description: 'Find the VAT Number of a company or entity.',
    icon: '/vat-search.png',
  },
  {
    link: '/spreadsheets',
    title: 'Other Resources',
    description: 'A list of other spreadsheets, reports and tools.',
  },
  {
    link: '/dashboards',
    title: 'Dashboards',
    description: 'All our dashboards in one place.',
  },
];

const sections = [
  {
    title: 'HR & Recruitment',
    shortcuts: shortcuts.slice(0, 2),
  },
  {
    title: 'Internal Communication & Tools',
    shortcuts: shortcuts.slice(2, 10),
  },
  {
    title: 'Resources & Others',
    shortcuts: shortcuts.slice(10, 14),
  },
];

export const DashboardView: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const [textSearch, setTextSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const debouncedTextSearch = useDebounce(textSearch, 300);
  const [data, setData] = React.useState<PagedClients | undefined>();

  const fetchData = React.useCallback(() => {
    if (debouncedTextSearch.length === 0) {
      setData(undefined);
      return;
    }
    setLoading(true);
    fetchClients(1, 6, debouncedTextSearch)
      .then((d) => {
        setData(d);
      })
      .finally(() => setLoading(false));
  }, [debouncedTextSearch]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <ActivityFeed />
      </Grid>
      <Grid item xs={12} md={4}>
        <Card className={classes.card}>
          <CardHeader
            title="Client Search"
            subheader="Search for clients across different platforms. Click 'Advanced Search' for more results and filters."
            action={
              <Button color="secondary" href="/clients" target="_blank">
                Advanced Search
              </Button>
            }
          />
          <CardContent>
            <div style={{ position: 'relative' }}>
              <TextField
                variant="outlined"
                placeholder="Type here to search..."
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: loading ? <CircularProgress size={20} /> : null,
                }}
              />
              {data?.data && data.data.length > 0 && (
                <List className={classes.searchResultList}>
                  {data.data.map((client) => (
                    <ListItem
                      key={client.id}
                      className={classes.clickableListItem}
                      onClick={() => history.push(`/clients/${client.id}`)}
                    >
                      <ListItemAvatar>
                        <Avatar {...stringAvatar(client.company_title)} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={client.company_title}
                        secondary={
                          <>
                            {resolvePlatformName(client.id)}
                            {Boolean(client.merge_count) &&
                              resolveMergedAliasesToPlatformNames(
                                client.merged_aliases ?? null,
                              ).length > 0 &&
                              ' and '}
                            {Boolean(client.merge_count) &&
                              resolveMergedAliasesToPlatformNames(
                                client.merged_aliases ?? null,
                              ).length > 0 && (
                                <Tooltip
                                  title={
                                    <>
                                      {resolveMergedAliasesToPlatformNames(
                                        client.merged_aliases ?? null,
                                      ).map((name, index) => (
                                        <div key={index}>{name}</div>
                                      ))}
                                    </>
                                  }
                                  placement="top"
                                >
                                  <Chip
                                    label={`${client.merge_count} ${
                                      client.merge_count! > 1
                                        ? 'merges'
                                        : 'merge'
                                    }`}
                                    size="small"
                                  />
                                </Tooltip>
                              )}
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
            {data && data.data && data.total_count > 0 && (
              <Typography variant="caption" color="textSecondary">
                Showing {data.data.length} of {data.total_count} result
                {data.total_count !== 1 ? 's' : ''}. Click 'Advanced Search' for
                more.
              </Typography>
            )}
          </CardContent>
        </Card>

        <Alert severity="warning" className={classes.card}>
          Information found in the intranet is highly confidential.
          <strong> Never share this information</strong> outside of
          Expedition42.
        </Alert>

        <Card className={classes.card}>
          <CardHeader
            title="Expedition42 Tools"
            subheader="Quick links to our tools and platforms."
          />
          <CardContent>
            {sections.map((section) => (
              <React.Fragment key={section.title}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {section.title}
                </Typography>
                <Grid container spacing={1}>
                  {section.shortcuts.map((shortcut) => (
                    <Grid item xs={4} sm={3} key={shortcut.link}>
                      <Tooltip title={shortcut.description} placement="top">
                        <Card
                          className={classes.toolCard}
                          onClick={() => window.open(shortcut.link, '_blank')}
                        >
                          {shortcut.icon ? (
                            <img
                              src={shortcut.icon}
                              alt={shortcut.title}
                              className={classes.toolIcon}
                            />
                          ) : (
                            <Folder className={classes.toolIcon} />
                          )}
                          <Typography
                            variant="body2"
                            className={classes.toolTitle}
                          >
                            {shortcut.title}
                          </Typography>
                        </Card>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
                {section !== sections[sections.length - 1] && (
                  <Divider style={{ margin: '16px 0' }} />
                )}
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
