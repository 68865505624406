import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  PagedClients,
  Client,
  companyIndustries,
  companyLeaders,
} from 'lib/Model/Client';

export function fetchClients(
  pageNumber: number,
  itemsPerPage: number,
  textSearch?: string,
  brand?: string[],
  churn?: string[],
  leader?: string[],
  headcount?: string[],
  industry?: string[],
  hasOpenDeal?: string,
  signal?: AbortSignal,
): Promise<PagedClients> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        company_title: textSearch?.length ? textSearch : undefined,
        brand: brand,
        churn: churn,
        leader: leader,
        headcount: headcount,
        industry: industry,
        has_open_deal: hasOpenDeal,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
      signal,
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Client[],
      };
    });
}

export function fetchClient(id: string): Promise<Client> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${id}`, {
      headers: {
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data as Client;
    });
}

export function fetchMbrUuid(cNumber: string): Promise<string> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/mbr/${cNumber}`, {
      headers: {
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function fetchCompanyIndustries(): Promise<companyIndustries[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/industries`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as companyIndustries[];
    });
}

export function fetchCompanyLeaders(): Promise<companyLeaders[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/zendesk_leaders`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as companyLeaders[];
    });
}

export function exportCompaniesList(
  textSearch?: string,
  brand?: string[],
  churn?: string[],
  leader?: string[],
  headcount?: string[],
  industry?: string[],
  hasOpenDeal?: string,
): Promise<any> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/export`, {
      params: {
        company_title: textSearch?.length ? textSearch : undefined,
        brand: brand,
        churn: churn,
        leader: leader,
        headcount: headcount,
        industry: industry,
        has_open_deal: hasOpenDeal,
      },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      // setting todays date and time to the filename. should be ok. overwriting pythons filename
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `client_list_export_${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}
